.rwe-content-container {
    position: relative;
    width: 100%;
    max-width: 1600px;

    padding: 0 72px;

    @include rwe-breakpoint-down(mobile) {
        padding: 0 16px;
    }
}