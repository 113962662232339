$horizontal-space-between-grid-items: 24px;
$vertical-space-between-grid-items: 48px;

.rwe-flex-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    bottom: -$vertical-space-between-grid-items;

    margin-top: -$vertical-space-between-grid-items;
    margin-right: -$horizontal-space-between-grid-items;

    &--carousel {

        &-extra-large-desktop {
            $horizontal-space: 72px;

            @include rwe-breakpoint-up(extra-large-desktop) {
                flex-wrap: nowrap;
                overflow: scroll;

                padding-left: $horizontal-space;

                margin-left: -$horizontal-space;
                margin-right: -$horizontal-space;

                scroll-snap-type: x mandatory;
                scroll-padding: $horizontal-space;

                & .rwe-flex-grid__item {
                    scroll-snap-align: start;
                }
            }
        }

        &-large-desktop {
            $horizontal-space: 72px;

            @include rwe-breakpoint-only(large-desktop) {
                flex-wrap: nowrap;
                overflow: scroll;

                padding-left: $horizontal-space;

                margin-left: -$horizontal-space;
                margin-right: -$horizontal-space;

                scroll-snap-type: x mandatory;
                scroll-padding: $horizontal-space;

                & .rwe-flex-grid__item {
                    scroll-snap-align: start;
                }
            }
        }

        &-desktop {
            $horizontal-space: 72px;

            @include rwe-breakpoint-only(desktop) {
                flex-wrap: nowrap;
                overflow: scroll;

                padding-left: $horizontal-space;

                margin-left: -$horizontal-space;
                margin-right: -$horizontal-space;

                scroll-snap-type: x mandatory;
                scroll-padding: $horizontal-space;

                & .rwe-flex-grid__item {
                    scroll-snap-align: start;
                }
            }
        }

        &-tablet {
            $horizontal-space: 32px;

            @include rwe-breakpoint-only(tablet) {
                flex-wrap: nowrap;
                overflow: scroll;

                padding-left: $horizontal-space;

                margin-left: -$horizontal-space;
                margin-right: -$horizontal-space;

                scroll-snap-type: x mandatory;
                scroll-padding: $horizontal-space;

                & .rwe-flex-grid__item {
                    scroll-snap-align: start;
                }
            }
        }

        &-mobile {
            $horizontal-space: 16px;

            @include rwe-breakpoint-down(mobile) {
                flex-wrap: nowrap;
                overflow: scroll;

                padding-left: $horizontal-space;

                margin-left: -$horizontal-space;
                margin-right: -$horizontal-space;

                scroll-snap-type: x mandatory;
                scroll-padding: $horizontal-space;

                & .rwe-flex-grid__item {
                    scroll-snap-align: start;
                }
            }
        }
    }

    @for $i from 1 through 10 {
        $size: (100 / $i) * 1%;

        &--col-extra-large-desktop-#{$i} {
            @include rwe-breakpoint-up(extra-large-desktop) {
                @if $i == 1 {
                    margin-right: 0;
                }
            }

            & .rwe-flex-grid__item {

                @include rwe-breakpoint-up(extra-large-desktop) {
                    @if $i == 1 {
                        padding-right: 0;
                    }
                    flex-basis: $size;
                    max-width: $size;
                }
            }
        }

        &--col-large-desktop-#{$i} {
            @include rwe-breakpoint-only(large-desktop) {
                @if $i == 1 {
                    margin-right: 0;
                }
            }

            & .rwe-flex-grid__item {

                @include rwe-breakpoint-only(large-desktop) {
                    @if $i == 1 {
                        padding-right: 0;
                    }
                    flex-basis: $size;
                    max-width: $size;
                }
            }
        }

        &--col-desktop-#{$i} {
            @include rwe-breakpoint-only(desktop) {
                @if $i == 1 {
                    margin-right: 0;
                }
            }

            & .rwe-flex-grid__item {

                @include rwe-breakpoint-only(desktop) {
                    @if $i == 1 {
                        padding-right: 0;
                    }
                    flex-basis: $size;
                    max-width: $size;
                }
            }
        }

        &--col-tablet-#{$i} {
            @include rwe-breakpoint-only(tablet) {
                @if $i == 1 {
                    margin-right: 0;
                }
            }

            & .rwe-flex-grid__item {

                @include rwe-breakpoint-only(tablet) {
                    @if $i == 1 {
                        padding-right: 0;
                    }
                    flex-basis: $size;
                    max-width: $size;
                }
            }
        }

        &--col-mobile-#{$i} {
            @include rwe-breakpoint-down(mobile) {
                @if $i == 1 {
                    margin-right: 0;
                }
            }

            & .rwe-flex-grid__item {

                @include rwe-breakpoint-down(mobile) {
                    @if $i == 1 {
                        padding-right: 0;
                    }
                    flex-basis: $size;
                    max-width: $size;
                }
            }
        }
    }

    &__item {
        flex-basis: auto;

        padding-bottom: $vertical-space-between-grid-items;
        padding-right: $horizontal-space-between-grid-items;

        &--spacer {
            height: 0;
            overflow: hidden;
            padding-bottom: 0;
        }

        &.-no-spacing {
          padding-bottom: 0;
        }
    }
}
