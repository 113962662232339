.rwe-breadcrumb-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.rwe-breadcrumb {
    color: $primary--charcoal-black;

    a {
        text-decoration: underline;
        text-decoration-color: currentColor;
    }
}

.rwe-breadcrumb-separator {
    margin: 0 7px;
}

.rwe-breadcrumb-list-section {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 8px 0;

    width: 100%;
}