.rwe-drawer {
    $block: &;

    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    bottom: 0;
    width: calc(100% - 60px);

    z-index: 1;

    transition: top .5s, left .5s;

    background-color: $primary--white;

    &--open {
        left: 0%;
    }

    &-backdrop {
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #1C1510;
        opacity: 0;

        z-index: 1;

        transition: opacity .7s, visibility .7s;

        &--show {
            visibility: visible;

            opacity: .5;
        }
    }

    &__close-icon {
        @include icon($icon-close-large);
        font-size: 200%;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 32px;
    }

    &__items {
        flex: 1;
        overflow: scroll;
    }

    &__item {
        padding: 32px;

        border-bottom: 3px solid $slate--very-light-slate;

        transition: border-bottom .1s .5s;

        &--expanded {
            border-bottom: 3px solid transparent;

            transition: border-bottom 0s;
        }

        &-text {
            display: flex;
            flex-direction: row;
            align-items: center;

            color: $primary--charcoal-black;
            white-space: pre;
            user-select: none;

            a {
                color: $primary--charcoal-black;
            }

            &--with-arrow-on-right {
                position: relative;

                padding-right: 20px;

                // An arrow pointing to the right
                &::after {
                    position: absolute;
                    right: 0;

                    content: '';

                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 5px solid $primary--charcoal-black;
                }


                #{$block}__item--expanded  & {
                    // An arrow pointing down
                    &::after {
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid $primary--charcoal-black;
                    }
                }
            }
        }

        &-left-accessory-container {
            display: flex;
            align-items: center;
            margin-right: 12px;
        }
    }

    &__expandable-menu {
        max-height: 0px;
        overflow: hidden;

        transition: max-height .5s;

        padding-left: 28px;

        &--expanded {
            max-height: 150vh;
        }

        &-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 28px;

            margin: 16px 0;

            border-left: 3px solid $slate--very-light-slate;

            white-space: pre;
            user-select: none;

            a {
                color: $primary--charcoal-black;
            }

            &-left-accessory-container {
                display: flex;
                align-items: center;
                margin-right: 12px;
            }
        }

        &-item-header {
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 28px;

            margin: 16px 0;

            color: $slate--mid-slate;
            white-space: pre;
            user-select: none;
        }
    }

    &__footer {
        display: flex;
        padding: 32px;
        align-items: center;
        justify-content: space-between;
        width: 280px;
        a:first-child{
            //margin-right: 35px;
        }
    }
}
