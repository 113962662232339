@import '../../../fonts/Icomoon/desktop/style.scss';

.rwe-collapsible-section-list {
    > .rwe-collapsible-section {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.rwe-collapsible-section {
    $block: &;

    background-color: $primary--white;

    border-radius: 2px;

    box-shadow: 0px 7px 20px -6px #C9C7DD;

    margin-bottom: 16px;

    &__content-container {
        display: flex;
        flex-direction: row;

        overflow: hidden;
        
        transition: height .2s;
    }

    &__header-and-body {
        flex: 1;
    }

    &__header {
        padding: 16px;

        cursor: pointer;
    }

    &__body {
        padding: 0 16px 16px 16px;
    }

    &__icon {
        font-size: 200%;
        @include icon($icon-minus);
        
        #{$block}--collapsed & {
            @include icon($icon-add);
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 16px;

            cursor: pointer;
        }
    }
}