.rwe-feature {
    position: relative;
    width: 100%;

    border-radius: 5px;

    padding-top: 100%;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 8px;
    }

    &__icon {
        display: flex;
        align-items: center;
        
        flex: 1;

        padding: 8px;

        min-height: 1px;
        max-height: 64px;
        max-width: 64px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        @extend .rwe-p3;
        @include line-clamp(2);

        max-width: 100%;
        
        text-align: center;
        color: $primary--charcoal-black;

        margin-bottom: 8px;

        &:first-child {
            margin-bottom: 0;
        }

    }
}

.rwe-features-section {
    
    .rwe-flex-grid {
        $space: 16px;

        bottom: -$space;

        margin-top: -$space;
        margin-right: -$space;

        &__item {
            min-width: 130px;
            max-width: 160px;

            flex: 1;

            padding-bottom: $space;
            padding-right: $space;

            &--spacer {
                padding-bottom: 0;
            }
        }

        @include rwe-breakpoint-down(mobile) {
            $space--mobile: 8px;

            bottom: -$space--mobile;
    
            margin-top: -$space--mobile;
            margin-right: -$space--mobile;

            &__item {
                min-width: 115px;
                max-width: 150px;

                padding-bottom: $space--mobile;
                padding-right: $space--mobile;

                &--spacer {
                    padding-bottom: 0;
                }
            }

        }
    }
}