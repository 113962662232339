.rwe-memberships-search-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 72px;

    &__pane {
      flex: 1;

      &:nth-child(1) {
        min-width: 800px;
      }

      &:nth-child(2) {
        padding-left: 40px;
        position: sticky;
        top: 0px;
        margin-right: -72px;
      }

      @media (max-width: 1200px) {
        &:nth-child(1) {
          min-width: 100%;
        }

        &:nth-child(2) {
          display: none;
        }
      }
    }
}

.rwe-tablet-filters-section__right button,
.rwe-mobile-filters-section__right button {
  @media (max-width: 1200px) {
    display: none;
  }
}
