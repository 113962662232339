﻿// Responsive Scale
:root {
  --scale: 1;

  @include media-breakpoint-only(xxl) {
    --scale: 1.1;
  }

  @include media-breakpoint-only(lg) {
    --scale: 0.9;
  }

  @include media-breakpoint-only(md) {
    --scale: 0.8;
  }

  @include media-breakpoint-down(sm) {
    --scale: 0.7;
  }
}

html {
  scroll-behavior: smooth;
}
#popUpFormBlock{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 9999;
  top: 0;
  overflow-y: auto;
}

#popUpFormBlockMeeting{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 9999;
  top: 0;
  overflow-y: auto;
  overflow: hidden;
}

#popUpFormBlock1{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 9999;
  top: 0;
  overflow-y: auto;
}

#popUpFormBlockMeeting1{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 9999;
  top: 0;
  overflow-y: auto;
  overflow: hidden;
}


#popUpFormBlock .rwe-section{
  background: transparent;
}

#popUpFormBlockMeeting .rwe-section{
  background: transparent;
  padding-top: 40px;
}

#popUpFormBlock1 .rwe-section{
  background: transparent;
}

#popUpFormBlockMeeting1 .rwe-section{
  background: transparent;
  padding-top: 40px;
}

#popUpFormBlock .rwe-section .rwe-content-container{
  background: #fff;
  padding-top: 25px;
  border-radius: 3px;
  padding-bottom: 25px;
}

#popUpFormBlockMeeting .rwe-section .rwe-content-container{
  background: #fff;
  padding-top: 25px;
  border-radius: 3px;
  padding-bottom: 25px;
}

#popUpFormBlock1 .rwe-section .rwe-content-container{
  background: #fff;
  padding-top: 25px;
  border-radius: 3px;
  padding-bottom: 25px;
}

#popUpFormBlockMeeting1 .rwe-section .rwe-content-container{
  background: #fff;
  padding-top: 25px;
  border-radius: 3px;
  padding-bottom: 25px;
}

.CloseBtn{
  display: none;
}

#popUpFormBlock .CloseBtn{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #000;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  cursor: pointer;
}

#popUpFormBlockMeeting .CloseBtn{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #000;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  cursor: pointer;
}

#popUpFormBlock1 .CloseBtn{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #000;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  cursor: pointer;
}

#popUpFormBlockMeeting1 .CloseBtn{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #000;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  cursor: pointer;
}

#popUpFormBlock .rwe-content-container div:nth-child(3){
  margin-right: 0 !important;
}

#popUpFormBlockMeeting .rwe-content-container div:nth-child(3){
  margin-right: 0 !important;
}

#popUpFormBlock1 .rwe-content-container div:nth-child(3){
  margin-right: 0 !important;
}

#popUpFormBlockMeeting1 .rwe-content-container div:nth-child(3){
  margin-right: 0 !important;
}


.rwe-custom-site-page__subHeader{
  position: fixed;
  width: 100%;
  max-width: none;
  padding: 0 72px;
  display: flex;
  z-index: 2;
  background: #fff;
  top: 0;
  transition: all 0.5s;
  padding-bottom: 14px;
}

.rwe-custom-site-page__subHeader li{
  list-style-type: none;
  margin-right: 30px;
}

.rwe-custom-site-page__subHeader li a{
  padding-bottom: 11px;
  padding-left: 5px;
  padding-right: 5px;
}
.rwe-carousel-window__item{
  position: relative;
}
.image-caption{
  display: none;
}
.rwe-carousel-window__item .image-caption{
  display: block;
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 4px 8px;
  border: 1px solid #E3E2EE;
  background-color: #FFFFFF;
}

.rwe-carousel__indicator .image-caption{
  display: none;
}
.rwe-custom-site-page__subHeader li a:hover{
  border-bottom: 5px solid #20caab !important;
  color: #20caab !important;
}

// .FullScreenImg{
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   z-index: -1;
//   left: 0;
//   top: 0;
//   background: rgba(1, 1, 1, 0.7);
// }

// .FullScreenImg img{
//   top: 50%;
//   transform: translateY(-50%);
//   position: absolute;
//   width: 100%;
// }

// #FullScreenImg .CloseBtn{
//   display: block;
//   width: 15px;
//   height: 15px;
//   position: absolute;
//   right: 10px;
//   top: 10px;
//   background-color: #000;
//   clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
//   cursor: pointer;
//   z-index: 9;
// }

#popUpFormBlockMeeting .rwe-content-container{
  height: 720px
}

#popUpFormBlockMeeting .rwe-content-container iframe{
  height: 950px !important;
}

#popUpFormBlockMeeting1 .rwe-content-container{
  height: 720px
}

#popUpFormBlockMeeting1 .rwe-content-container iframe{
  height: 950px !important;
}

#selectBox{
  width: 15em;
  font-size: 16px;
  color: #565366;
}

.DontDisplayLaptop{
  display: none !important;
}
.DisplayLaptop{
  display: block !important;
}

#searchBlock{
  margin-top: 25px;
}

.search-wrapper{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 15px;
  padding-left: 0;
  border-radius: 10px;
  position: relative;
  background: #f0f0f0;
  height: 80px;
}

.search-wrapper-block{
  width: 28%;
  padding-left: 25px;
  align-self: center;
}

.search-open-btn{
  cursor: pointer;
}

.search-wrapper-block-center{
  border-left: 2px solid #20CAAB;
  border-right: 2px solid #20CAAB;
}

.search-wrapper-button{
  width: 16%;
}

.search-button{
  background: #20CAAB;
  color: #fff;
  text-align: center;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.search-button svg{
  margin-right: 5px;
}

.office-name-wrapper{
  display: flex;
  position: absolute;
  top: 85px;
  z-index: 9;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px 25px;
  left: 0;
  width: 55%;
}

.office-name-wrapper div:first-child{
  width: 57%;
}

.desks-number-wrapper{
  position: absolute;
  top: 85px;
  z-index: 9;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px 25px;
  left: 28%;
  width: 27%; 
}

.data-office-wrapper{
  position: absolute;
  top: 85px;
  z-index: 9;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 10px 25px;
  left: 55%;
  width: 27%; 
}

.gray-text{
  color: #a0a0a0;
}

#searchForm{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 9999;
  top: 0;
  overflow-y: auto;
} 

#searchForm{
  .rwe-section{
    width: 72%;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
}

#searchForm .CloseBtn{
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #000;
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  cursor: pointer;
  z-index: 99;
}

.office-name-choose, .office-desks-choose, .office-date-choose{
  cursor: pointer;
}

.office-name-choose:hover, .office-desks-choose:hover, .office-date-choose:hover{
  text-decoration: underline;
}

.load-wrapper{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background: rgba(0,0,0,.6); 
}

.load-wrapper-block{
  width: 64%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.error-result{
  display: none;
  position: absolute;
  background: #fff;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#searchForm .rwe-custom-html{
  height: 100%;
  position: absolute;
  width: 100% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#searchForm .rwe-section{
  height: 600px;
}

#searchForm .rwe-content-container{
  height: 100%;
  width: 90% !important;
}

#searchForm .submitted-message{
  padding: 27%;
  text-align: center;
}

.phone-num__wrapper{
  margin-right: 24px;
}

.phone-num__img{
  margin-right: 5px;
}

@media (min-width: 1200px){
  .rwe-location-site-page .rwe-simple-cta-section .rwe-section__content-container{
    align-items: flex-start;
  }
  .rwe-location-site-page .rwe-section__content-container{
    padding-top: 11px;
  }
  .rwe-location-site-page .rwe-simple-cta-section .rwe-section__content-container .rwe-section__content{
    padding-top: 31px;
  }
  @media (max-height: 800px){
    #popUpFormBlockMeeting .rwe-section{
      padding-top: 5px;
    }
    #popUpFormBlockMeeting1 .rwe-section{
      padding-top: 5px;
    }
  }
}

@media (max-width: 1200px){
  .rwe-custom-site-page__subHeader{
    display: none !important;
  }
}

@media (max-width: 767px){
  .phone-num__wrapper{
    margin-right: 0px;
  }
  
  .phone-num__img{
    margin-right: 0px;
  }
}

@media (max-width: 650px) {
  .rwe-custom-html iframe{
    height: 800px !important;
  }
}

@media (max-width: 500px) {
  .rwe-custom-html iframe{
    height: 980px !important;
  }
  #selectBox{
    width: 90%;
  }
  .DontDisplayMobile{
    display: none !important;
  }
  .DisplayMobile{
    display: block !important;
  }

  .search-wrapper{
    flex-direction: column;
    padding: 15px 25px;
    height: auto;
  }

  .search-wrapper-block{
    width: 100%;
    padding-left: 0;
  }

  .search-wrapper-block-center{
    border-top: 2px solid #20CAAB;
    border-bottom: 2px solid #20CAAB;
    border-left: none;
    border-right: none;
    padding: 10px 0;
    margin: 10px 0;
  }

  .search-wrapper-button {
    width: 100%;
    margin-top: 15px;
    height: 40px;
  }
  
  .office-name-wrapper{
    width: 100%;
  }

  .desks-number-wrapper{
    width: 100%;
    left: 0;
  }

  .data-office-wrapper{
    width: 100%;
    left: 0;
    top: 135px;
  }

  #searchForm .rwe-section{
    width: 90%;
    height: 100vh;
  }
  .load-wrapper-block{
    width: 80%;
  }

  #searchForm .submitted-message{
    padding: 50% 5%;
    text-align: center;
  }
}