.rwe-floating-cta-container {
    margin-bottom: 24px;
    margin-left: 24px;

    transition: top .5s;
    z-index: 1;

    @include rwe-breakpoint-down(tablet) {
        margin-bottom: 0;
        margin-left: 0;
    }
}

.rwe-floating-cta {
    padding: 38.5px 24px;

    border-radius: 5px;

    box-shadow: 0px 7px 20px -6px #C9C7DD;

    background-color: $primary--white;

    @include rwe-breakpoint-down(tablet) {
        position: fixed;
        top: unset!important;
        bottom: 0;
        left: 0;
        right: 0;

        margin: 0;

        padding: 24px 38.5px;
        
        box-shadow: 0px -5px 20px -6px rgba(76, 75, 91, 0.1);

        z-index: 1;
    }
    
    @include rwe-breakpoint-only(mobile) {
        padding: 16px 24px;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__text-content {
        display: flex;
        flex-direction: column;

        @include rwe-breakpoint-down(tablet) {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__body {
        margin-top: 16px;
        @include rwe-breakpoint-down(tablet) {
            display: none;
        }
    }

    &__price-and-prefix-and-suffix {
        display: flex;
        flex-direction: column;

        margin-top: 16px;

        color: $primary--charcoal-black;

        @include rwe-breakpoint-down(tablet) {
            margin-top: 0;
        }
    }

    &__price-and-suffix {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    &__price {
        line-height: 1;
    }

    &__buttons {
        display: flex;
        flex-direction: column;

        margin-top: 16px;


        @include rwe-breakpoint-down(tablet) {
            flex-direction: row;
        }

        > * {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            @include rwe-breakpoint-down(tablet) {
                margin-bottom: 0;
                margin-right: 16px;

                &:last-child {
                    margin-bottom: 0;
                    margin-right: 0;
                }
            }
        }
    }
}