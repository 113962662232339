.accordion {
  &__heading {
    font-weight: 500;
    padding: rem(25px) 0;
    cursor: pointer;

    .icon.upside-down {
      transform: scaleY(-1);
    }
  }

  hr {
    border-top: 1px solid $gray-300;
    margin: 0;
  }
}