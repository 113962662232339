// Reset
button.rwe-button-reset {
    cursor: pointer;
    outline: 0;
    padding: 0;
    border: 0;
    background: none;
}

.rwe-button {
    position: relative;
    top: 0px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 12px 16px;

    border-radius: 5px;

    transition: all .1s;

    @extend .rwe-p1;
    
    user-select: none;

    text-align: center;
    white-space: pre;

    text-decoration: none;

    &[disabled] {
        pointer-events: none;
        opacity: .4;
    }

    &:hover {
        top: 3px;

        padding-bottom: 9px;

        text-decoration: none;
    }

    > * {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.rwe-button--block {
    width: 100%;
}


/**
 * Variants
 **/
a.rwe-button--primary {
    color: $primary--white;
}
.rwe-button--primary {
    color: $primary--white;
    background-color: $primary--teal;
    border: none;

    box-shadow: 0px 3px 0px #17A98E;

    &:hover {
        color: $primary--white;
        background-color: #17A98E;

        box-shadow: 0px 0px 0px #17A98E;

        padding-bottom: 12px;
    }
}

a.rwe-button--primary-dark {
    color: $primary--white;
}
.rwe-button--primary-dark {
    color: $primary--white;
    background-color: #17A98E;
    border: none;

    &:hover {
        top: 0;
        
        padding-bottom: 12px;
    }

    &.rwe-button--active {
        color: $primary--charcoal-black;
        background-color: $primary--white
    }
}

a.rwe-button--secondary {
    color: $primary--teal;
}
.rwe-button--secondary {
    color: $primary--teal;
    background-color: $primary--white;
    border: 1px solid $primary--teal;

    box-shadow: 0px 3px 0px #17A98E;

    &:hover {
        color: $primary--teal;

        box-shadow: 0px 0px 0px #17A98E;

        padding-bottom: 12px;
    }
}

a.rwe-button--tertiary {
    color: $primary--teal;
}
.rwe-button--tertiary {
    color: $primary--teal;
    background-color: transparent;
    border: none;

    padding: 0px;

    &:hover {
        top: 0px;

        color: $secondary--blue-purple;

        padding: 0px;
    }

    & .rwe-button__content {
        text-decoration: underline;
        text-decoration-color: currentColor;

        .rwe-button--tertiary:hover & {
            text-decoration: underline;
        }
    }
}

a.rwe-button--floating {
    color: $primary--teal;
}
.rwe-button--floating {
    color: $primary--teal;
    background-color: $primary--white;
    border: none;
    border-radius: 1px;

    box-shadow: 0px 4px 10px -6px rgba(96, 92, 132, 0.2);


    padding: 38px 22px;

    &:hover {
        top: 0px;

        padding: 38px 22px;
        
        box-shadow: none;
    }
}

.rwe-button-group {
    display: flex;

    &--horizontal {
        flex-direction: row;

        > * {
            margin-right: 32px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &--vertical {
        flex-direction: column;
        align-items: flex-start;

        > * {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--responsive {
        flex-direction: row;

        @include rwe-breakpoint-down(mobile) {
            flex-direction: column;
            align-items: flex-start;
        }

        > * {
            margin-right: 32px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            @include rwe-breakpoint-down(mobile) {
                margin-right: 0;
                margin-bottom: 32px;

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}