.rwe-check-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    padding: 8px 24px;

    input {
        display: none;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &-child-items {
        padding-left: 16px;
    }

    &__text {
        padding-left: 8px;
    }
}

.rwe-check-box-tree {
    &__top-section {
        padding-top: 24px;
        padding-bottom: 16px;
        padding-left: 16px;

        color: $slate--dark-slate;
    }
}