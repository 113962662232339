﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Forms
-----------------------------------------
*/

.form-group {
  margin-bottom: rem(20px);

  & > label,
  .control-label {
    font-weight: 500;
    font-size: rem(14px);
    line-height: 1.4;
  }

  &-sm {
    margin-bottom: rem(12px);

    & > label,
    .control-label {
      font-weight: 400;
      color: $gray-700;
    }

    .form-control {
      height: $input-btn-height-sm;
      padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
      font-size: $input-btn-font-size-sm;
    }
  }

  &.border-0 {
    .form-control {
      border: none !important;
    }
  }

  .input-group {
    & > .form-control:not(:last-child),
    & > .custom-select:not(:last-child) {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      padding-right: rem(40px);
    }

    .input-group-append {
      position: absolute;
      top: 50%;
      right: $input-padding-x;
      transform: translateY(-50%);
      font-size: rem(24px);
      color: $gray-600;
      z-index: 5;
    }
  }

  .help-block {
    display: block;
    margin-top: rem(4px);

    &.validation-error {
      color: $red;
    }
  }
}

.form-control {
  @include transition();

  &-sm {
  }

  &:disabled {
  }

  &[readonly] {
  }
}

//Default controls
select.form-control {
  padding-right: ($custom-select-padding-x + $custom-select-indicator-padding);
  background: $custom-select-bg $custom-select-background;
  appearance: none;

  option[blank] {
    display: none;
  }

  &.nav-selector {
  }
}

textarea.form-control {
  padding: $input-padding-x;
  @include scrollbar();
}

input[type='file'].form-control {
  padding: 0;
  height: auto;
  border: none;
  background: transparent;

  &::-webkit-file-upload-button {
    height: $input-btn-height-sm;
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $input-btn-font-size-sm;
    background: $white;
    color: $gray-900;
    border-radius: $btn-border-radius;
    border: 1px solid $gray-400;
    margin-right: rem(8px);
  }
}

//Custom Controls
.custom-control-label {
  &::before {
    @include transition(0.2s);
  }

  &::after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.custom-select {
  width: auto;
  box-shadow: $custom-select-box-shadow;

  @include media-breakpoint-up(lg) {
    min-width: 160px;
  }

  option[blank] {
    display: none;
  }
}

.custom-checkbox {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::after {
        background-image: $custom-checkbox-indicator-icon-checked;
      }
    }
  }

  .custom-control-input:focus + label::before {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.custom-radio {
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::after {
        background-image: $custom-radio-indicator-icon-checked;
      }
    }
  }
}

.custom-switch {
  padding-left: #{$custom-switch-width + $input-padding-x};

  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::after {
          transform: translateX(
            $custom-switch-width - $custom-control-indicator-size -
              ($custom-switch-offset * 2)
          );
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA6lBMVEUAAAD///////////////////////////////////////////////////////////////////////////8ouV8su2Iuu2MvvGUwvGUyvWYzvWc0vWg1vmk2vmo4vms5v2w7wG08wG49wG8/wXBBwXJDwnRFw3VIxHdJxHdNxXpOxntVyIFWyIF51JuG2KWq5MCt5cK86s296s7B69HE7NPI7dbK7tfK7tjP8NvP8NzQ8NzU8d/V8uDa8+Pb9OXf9efg9ejj9urk9uvl9+vn+O3o+O7p+O/q+O/q+fDr+fDs+fHv+vTz+/b///9zZDEjAAAAFHRSTlMAAQkMFiNScn+Cg6Wqv9PZ6PLz/iznJ/UAAAE/SURBVHjajdVVYuQwEATQNjPJvbxhZsahME3q/scJx2yP3o/Z4hKVGZYXREJEgWcZ1Ep3QhSEjk5NNFugQtga1ZgxGsQmlSl2ikaprVCB6qOVr1JG8dHBz/9po5OdtSNFp/SrRVqMCWJNpuCscF1gIqETkQsJDhGFaHG3tfWcjTuRgRbXy8y72ZVBFppdLDL/HGWXFnloNJxn/nWAjEdBdn6/sdnHl/4M858j5AKKsvMd5v9n+HA+xfzvFAURiez8YZr57zHenPxlnu6hSFCCzGCO+fchcPibeXaAkoQi5EYLzD/39n8yL4xQFlGAgqsl5h8/mJcuURFUuud2ld+s3KDKq3b40zrz2iNqrNoQjre3x6gziEJICInIgQRHfuLKLwX5xSW/XOUDQD5SpENKPvbkg1QqmjO62xH20tvHK8jfVUy+1Xe3AAAAAElFTkSuQmCC);
        }
      }
    }
  }

  .custom-control-label {
    margin-bottom: 0;
    padding-top: #{(
        ($custom-control-indicator-size + ($custom-switch-offset * 2)) -
          $font-size-base
      ) / 4};

    &::before {
      top: (
          ($font-size-base * $line-height-base - $custom-control-indicator-size) /
            2
        ) - $custom-switch-offset;
      left: #{-1 * ($custom-switch-width + $input-padding-x)};
      height: $custom-control-indicator-size + ($custom-switch-offset * 2);
      border-radius: ($custom-control-indicator-size / 2) +
        $custom-switch-offset;
      border-color: $custom-switch-bg-color;
      background-color: $custom-switch-bg-color;
      @include transition(0.4s);
    }

    &::after {
      top: add(
        ($font-size-base * $line-height-base - $custom-control-indicator-size) /
          2,
        rem($custom-control-indicator-border-width * 2) - $custom-switch-offset
      );
      left: #{-1 * (
          $custom-switch-width + $input-padding-x - $custom-switch-offset
        )};
      height: $custom-control-indicator-size;
      width: $custom-control-indicator-size;
      border-radius: 50%;
      background-color: $white;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAATlBMVEUAAAD///////////////////////////////////////////////////////////////////////////+ZmZm2trbBwcHQ0NDp6en///9U11zsAAAAFHRSTlMAAQkMFiNScn+Cg6Wqv9PZ6PLz/iznJ/UAAADiSURBVHjaYkAFgMbKAl1DGAaCwd2q/97/oM8lH9Lu4DD1NjTdtGzGbMvUNfJIPaxQrEN9q1W9wQnTV1ev3XHD3p60ore4xfaF9soZj8ylyk97V/M/zx5J+r92WCSxPy2qdmTYK6bgv8Jrgyym/hBHEAwfoh7fl4/4IfqXHneRBgrvXMAXwTkPRSMdFNF9mD+ei1B0MkETvs2fi2KSBVfz6mGRDVfz6mETg6t59WDk4MSDL5puDN09dIfTQ0hPChlAMPATl18K/OJilisbAOiQwgcpPuzxgZQPzZp6TAd77vfxDmy+dh3mAkTnAAAAAElFTkSuQmCC);
    }
  }

  &--reversed {
    padding-left: 0;
    padding-right: #{$custom-switch-width + $input-padding-x};

    .custom-control-label {
      width: 100%;

      &::before {
        left: calc(100% + #{$input-padding-x});
      }

      &::after {
        left: calc(100% + #{$input-padding-x + $custom-switch-offset});
      }
    }
  }
}

.custom-datepicker {
  .control-label {
    min-height: rem(14px);
  }

  .rw-datetime-picker,
  .rw-widget-container {
    color: $gray-900;
    font-size: rem(14px);

    .rw-widget-picker {
      display: flex;
      align-items: center;
      padding: 0;
      outline: 0 !important;
      border-radius: $border-radius;
      border: $input-border-width solid $input-border-color;
      height: $input-btn-height;

      .rw-input {
        box-shadow: none;
        color: $gray-900;
        width: 100%;
        padding-right: 0;
        outline: 0 !important;
      }

      .rw-select {
        border: none !important;
        width: rem(40px);

        &:active,
        &:hover,
        &:focus {
          border: none !important;
          background: transparent;
        }

        .rw-btn-select {
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem(40px);

          i {
            font-size: rem(24px);
            color: $gray-600;
          }
        }
      }
    }

    &.rw-state-focus {
      .rw-widget-picker {
        border: $input-border-width solid $input-focus-border-color;
        box-shadow: none;
      }
    }

    &.rw-open {
      .rw-widget-picker {
        border-radius: $border-radius;
      }
    }
  }

  &.row {
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
    width: auto;

    .control-label {
      font-weight: 400;
      margin-bottom: 0;
      margin-right: rem(12px);
      font-size: rem(14px);
      color: $gray-700;
      min-height: 0;

      &:after {
        content: ':';
      }
    }

    .rw-datetime-picker {
      width: rem(160px);
    }

    .rw-datetime-picker,
    .rw-widget-container {
      .rw-widget-picker {
        height: $input-btn-height-sm;
        border: $input-border-width solid transparent;
        box-shadow: $custom-select-box-shadow;
      }

      .rw-calendar-popup,
      .rw-popup-container {
        left: auto;
      }

      &.rw-state-focus {
        .rw-widget-picker {
          border: $input-border-width solid $custom-select-focus-border-color;
          box-shadow: $box-shadow-sm;
        }
      }
    }

    &.time {
      .rw-datetime-picker,
      .rw-widget-container {
        width: rem(110px);
      }
    }
  }

  &.sm-72-100 {
    @include media-breakpoint-down(sm) {
      width: 100%;

      .control-label {
        width: rem(72px) !important;
        min-width: rem(72px) !important;
      }

      .rw-datetime-picker,
      .rw-widget-container {
        width: 100%;
      }
    }
  }
}

//Vendor controls
.react-tag-input {
  border-width: $input-border-width !important;
  border-color: $input-border-color !important;
  border-radius: $border-radius !important;
  min-height: $input-height !important;
  padding: #{$input-btn-padding-x-xs - rem(4px)} $input-btn-padding-x-xs !important;
  font-size: $input-btn-font-size !important;
  color: $input-color !important;

  &__tag {
    font-size: $input-btn-font-size-xs !important;
    background: $gray-200 !important;
    border-radius: $border-radius !important;
    height: $input-btn-height-xs !important;
    margin: rem(2px) !important;

    &__content {
      font-size: $input-btn-font-size-xs !important;
      padding: 0 $input-btn-padding-x-xs/2 0 $input-btn-padding-x-xs !important;
    }

    &__remove {
      border-top-right-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      background: $gray-200 !important;

      &:before,
      &:after {
        background-color: $gray-600 !important;
      }

      &:hover,
      &.active,
      &:focus {
        &:before,
        &:after {
          background-color: $gray-900 !important;
        }
      }
    }
  }

  &__input {
    @include placeholder(1, $input-placeholder-color, 400);
    height: calc(
      #{$input-height} - #{$input-btn-padding-x-xs * 2} - #{$input-border-width *
        2}
    ) !important;
    margin: 0 !important;
    padding: 0 $input-padding-x/2 !important;
    font-size: $input-btn-font-size !important;
    font-weight: 400 !important;
    color: $input-color !important;
  }
}

.react-mde {
  border-radius: $border-radius !important;
  overflow: hidden;

  .mde-header {
    border-radius: $border-radius $border-radius 0 0 !important;

    & + div {
      border-radius: 0 0 $border-radius $border-radius !important;
    }
  }
}

.mentions-input {
  .form-control__control {
    font-family: $font-family-sans-serif !important;

    .form-control__highlighter,
    .form-control__input {
      padding: $input-padding-x !important;
      font-size: $input-btn-font-size !important;
      @include placeholder(1, $input-placeholder-color, 400);
    }
  }
}

.rw-datetime-picker {
}

.rw-multiselect {
  .rw-widget-container {
    border: $gray-300 1px solid;
  }

  &-taglist {
    vertical-align: top;
    margin-top: 1px;

    .rw-multiselect-tag {
      background: $gray-200;
      border: 0;
      border-radius: $border-radius;

      &-btn {
        span {
          vertical-align: text-bottom;
        }
      }
    }
  }
}

.input-radio-box.input-radio-box {
  flex: 0 1 49% !important;
  max-width: 49%;
  border: 1px solid $gray-300;
}

.number-input-container {
  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  
  .number-input {
    display: inline-flex;
    flex: 1.5;
  }

  label {
    flex: 2;
  }
  
  .number-input,
  .number-input * {
    box-sizing: border-box;
  }
  
  .number-input button {
    outline:none;
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid $gray-300;
    border-radius: calc(var(--border-radius) * 0.5);
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    margin: 0;
    position: relative;
  }
  
  .number-input button:before {
    display: inline-block;
    position: absolute;
    content: '-';
    transform: translate(-50%, -55%);
  }
  
  .number-input button.plus:before {
    content: '+';
  }
  
  .number-input input[type=number] {
    font-family: sans-serif;
    max-width: 3rem;
    padding: 0;
    border: 0;
    font-size: 1rem;
    height: 1.25rem;
    font-weight: bold;
    text-align: center;
  }
}