﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Buttons
-----------------------------------------
*/

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: $input-btn-height;
  padding: $input-btn-padding-y $input-btn-padding-x;
  font-size: $input-btn-font-size;
  box-shadow: $btn-box-shadow;
  border-radius: $btn-border-radius;
  line-height: $btn-line-height;
  transition: $btn-transition;
  background: $bg-color;
  color: $text-color;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  max-width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    box-shadow: $btn-box-shadow;
    background: $hover-color;
    color: $text-color;
    text-decoration: none;
  }

  & > .caret {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    position: relative;
    width: rem(12px);
    height: rem(6px);
    margin-left: rem(10px);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-top: 6px solid $text-color;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    &:after {
      content: '';
      position: absolute;
      left: 2px;
      top: 0;
      border-top: 4px solid $bg-color;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
  }

  &.btn-lg {
    height: $input-btn-height-lg;
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
    font-size: $input-btn-font-size-lg;
  }

  &.btn-sm {
    height: $input-btn-height-sm;
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $input-btn-font-size-sm;
  }

  &.btn-xs {
    height: $input-btn-height-xs;
    padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
    font-size: $input-btn-font-size-xs;

    & > .caret {
      width: rem(12px);
      height: rem(6px);
      margin-left: rem(6px);

      &:before {
        border-top-width: 6px;
        border-left-width: 6px;
        border-right-width: 6px;
      }

      &:after {
        border-top-width: 4px;
        border-left-width: 4px;
        border-right-width: 4px;
      }
    }
  }

  &.btn-icon {
    padding-left: $input-btn-padding-x/2;

    & > i {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-right: $input-btn-padding-x/2;
      font-size: $input-btn-icon-size;
    }

    & > .higlight-bar {
      display: inline-block;
      width: rem(4px);
      height: $input-btn-icon-size-xs;
      border-radius: rem(2px);
    }

    &.btn-lg {
      padding-left: $input-btn-padding-x-lg/2;

      & > i {
        margin-right: $input-btn-padding-x-lg/2;
        font-size: $input-btn-icon-size-lg;
      }
    }

    &.btn-sm {
      padding-left: $input-btn-padding-x-sm/2;

      & > i {
        margin-right: $input-btn-padding-x-sm/2;
        font-size: $input-btn-icon-size-sm;
      }
    }

    &.btn-xs {
      padding-left: $input-btn-padding-x-xs/2;

      & > i {
        margin-right: $input-btn-padding-x-xs/2;
        font-size: $input-btn-icon-size-xs;
      }
    }
  }

  &.btn-single-icon {
    padding: 0;
    width: $input-btn-height;
    text-align: center;
    justify-content: center;

    & > i {
      display: inline-block;
      line-height: 1;
      font-size: $input-btn-icon-size;
    }

    & > .badge {
      position: absolute;
      top: $input-btn-padding-x / 4;
      right: $input-btn-padding-x / 4;
      width: rem(16px);
      height: rem(16px);
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(12px);
      font-weight: 500;
      line-height: 1;
      background: $red;
      color: $white;
      animation: none;
      &.wiggle {
        animation: wiggle 2.5s infinite;
      }
    }

    &.btn-lg {
      width: $input-btn-height-lg;

      & > i {
        font-size: $input-btn-icon-size-lg;
      }
    }

    &.btn-sm {
      width: $input-btn-height-sm;

      & > i {
        font-size: $input-btn-icon-size-sm;
      }
    }

    &.btn-xs {
      width: $input-btn-height-xs;

      & > i {
        font-size: $input-btn-icon-size-xs;
      }
    }
  }

  &.btn-link {
    justify-content: normal;
    height: auto;
    padding: 0;
    font-size: $font-size-base;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    color: $link-color;
    text-decoration: underline;
    text-decoration-color: transparent;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      text-decoration-color: currentColor;
    }

    &.btn-sm {
      height: auto;
      padding: 0;
      font-size: $input-btn-font-size-sm;

      i {
        font-size: $input-btn-icon-size-sm;
      }
    }

    &.btn-xs {
      height: auto;
      padding: 0;
      font-size: $input-btn-font-size-xs;

      i {
        font-size: $input-btn-icon-size-xs;
      }
    }

    &.text-gray-600 {
      &:hover,
      &:active,
      &:focus {
        color: $gray-700 !important;

        & > .caret {
          &:before {
            border-top-color: $gray-700;
          }
        }
      }

      & > .caret {
        &:before {
          border-top-color: $gray-600;
        }
      }
    }

    &.text-gray-700 {
      &:hover,
      &:active,
      &:focus {
        color: $gray-900 !important;

        & > .caret {
          &:before {
            border-top-color: $gray-900;
          }
        }
      }

      & > .caret {
        &:before {
          border-top-color: $gray-700;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $link-color;
      }

      &:after {
        border-top-color: transparent;
      }
    }
  }

  &.btn-outline {
    box-shadow: none;
    background: transparent;
    color: $gray-900;
    border: 1px solid $gray-400;

    i {
      opacity: 0.8;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-300;
      color: $gray-900;

      i {
        opacity: 1;
        color: $gray-900 !important;
      }

      & > .caret {
        &:before {
          border-top-color: $gray-900;
        }

        &:after {
          border-top-color: $gray-300;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-900;
      }

      &:after {
        border-top-color: $white;
      }
    }
  }

  &.btn-outline-gray {
    box-shadow: none;
    background: transparent;
    color: $gray-700;
    border: 1px solid $gray-400;

    i {
      color: $gray-700;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-300;
      color: $gray-900;

      i {
        color: $gray-900 !important;
      }

      & > .caret {
        &:before {
          border-top-color: $gray-700;
        }

        &:after {
          border-top-color: $gray-300;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-900;
      }

      &:after {
        border-top-color: $gray-300;
      }
    }
  }

  &.btn-select {
    box-shadow: none;
    background: transparent;
    color: $gray-900;
    font-weight: 400;
    border: $input-border-width solid $input-border-color;
    display: flex;
    justify-content: space-between;
    width: 100%;

    i {
      opacity: 0.8;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: transparent;
      color: $gray-900;

      i {
        opacity: 1;
        color: $gray-900 !important;
      }

      & > .caret {
        &:before {
          border-top-color: $gray-900;
        }

        &:after {
          border-top-color: $white;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-600;
      }

      &:after {
        border-top-color: $white;
      }
    }
  }

  &.btn-gray {
    box-shadow: none;
    background: $gray-200;
    color: $gray-900;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-300;

      & > .caret {
        &:after {
          border-top-color: $gray-300;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-900;
      }

      &:after {
        border-top-color: $gray-200;
      }
    }
  }

  &.btn-gray-light {
    box-shadow: none;
    border: none;
    background: $gray-100;
    color: $gray-600;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      border: none !important;
      background: $gray-300 !important;
      color: $gray-900 !important;

      & > .caret {
        &:before {
          border-top-color: $gray-700;
        }

        &:after {
          border-top-color: $gray-300;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-600;
      }

      &:after {
        border-top-color: $gray-100;
      }
    }

    &.active {
      box-shadow: $btn-box-shadow !important;
      background: $bg-color !important;
      color: $text-color !important;

      &:hover,
      &:active,
      &:focus {
        box-shadow: $btn-box-shadow;
        background: $bg-color;
        color: $text-color;
      }
    }

    &.active-bg-black {
      box-shadow: $btn-box-shadow !important;
      background: $gray-900 !important;
      color: $white !important;

      &:hover,
      &:active,
      &:focus {
        box-shadow: $btn-box-shadow;
        background: $gray-900;
        color: $white;
      }

      & > .caret {
        &:before {
          border-top-color: $white;
        }

        &:after {
          border-top-color: $gray-900;
        }
      }
    }
  }

  &.btn-gray-white {
    box-shadow: none;
    background: $gray-300;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-900;

      & > .caret {
        &:after {
          border-top-color: $gray-900;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $white;
      }

      &:after {
        border-top-color: $gray-300;
      }
    }
  }

  &.btn-white {
    box-shadow: none;
    background: $white;
    color: $gray-600;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-200;
      color: $gray-700;

      & > .caret {
        &:before {
          border-top-color: $gray-700;
        }

        &:after {
          border-top-color: $gray-200;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-600;
      }

      &:after {
        border-top-color: $white;
      }
    }
  }

  &.btn-white-black {
    box-shadow: none;
    background: $white;
    color: $gray-900;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      background: $gray-200;
      color: $gray-900;

      & > .caret {
        &:before {
          border-top-color: $gray-900;
        }

        &:after {
          border-top-color: $gray-200;
        }
      }
    }

    & > .caret {
      &:before {
        border-top-color: $gray-900;
      }

      &:after {
        border-top-color: $white;
      }
    }

    &.fullcalendar-active {
      box-shadow: none;
      background: $gray-300;
      color: $gray-900;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        background: $gray-300;
        color: $gray-900;
      }
    }
  }

  &.btn-op-02 {
    box-shadow: none !important;
    background: transparent !important;
    color: $gray-900 !important;
    opacity: 0.2;

    &:hover,
    &:active,
    &:focus {
      opacity: 0.4;
    }

    &.active {
      opacity: 1;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }

  &.btn-tag {
    height: $input-btn-height-xs;
    padding: $input-btn-padding-y-xs $input-btn-padding-x-xs;
    font-size: $input-btn-font-size-xs;
    box-shadow: none;
    background: $gray-200;
    color: $gray-900;
    cursor: default !important;
    margin-right: $input-btn-padding-x-xs / 2;
    margin-bottom: $input-btn-padding-x-xs / 2;
  }

  &.active {
    background: $bg-color;
    color: $text-color;
    cursor: default !important;

    &:hover,
    &:active,
    &:focus {
      background: $bg-color;
      color: $text-color;
    }
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: rem(-4px);

  .btn {
    border-radius: $btn-border-radius !important;
    margin: rem(4px) !important;
    flex: 0 1 auto;
  }

  &.center {
    justify-content: center;
  }
}

//Responsive versions
.btn-md-sm {
  @include media-breakpoint-only(md) {
    height: $input-btn-height-sm !important;
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
    font-size: $input-btn-font-size-sm !important;

    &.btn-icon {
      padding-left: $input-btn-padding-x-sm/2 !important;

      & > i {
        margin-right: $input-btn-padding-x-sm/2 !important;
        font-size: $input-btn-icon-size-sm !important;
      }
    }
  }
}

.btn-md-xs {
  @include media-breakpoint-only(md) {
    height: $input-btn-height-xs !important;
    padding: $input-btn-padding-y-xs $input-btn-padding-x-xs !important;
    font-size: $input-btn-font-size-xs !important;

    &.btn-icon {
      padding-left: $input-btn-padding-x-xs/2 !important;

      & > i {
        margin-right: $input-btn-padding-x-xs/2 !important;
        font-size: $input-btn-icon-size-xs !important;
      }
    }
  }
}

.btn-sm-sm {
  @include media-breakpoint-only(sm) {
    height: $input-btn-height-sm !important;
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
    font-size: $input-btn-font-size-sm !important;

    &.btn-icon {
      padding-left: $input-btn-padding-x-sm/2 !important;

      & > i {
        margin-right: $input-btn-padding-x-sm/2 !important;
        font-size: $input-btn-icon-size-sm !important;
      }
    }
  }
}

.btn-sm-xs {
  @include media-breakpoint-only(sm) {
    height: $input-btn-height-xs !important;
    padding: $input-btn-padding-y-xs $input-btn-padding-x-xs !important;
    font-size: $input-btn-font-size-xs !important;

    &.btn-icon {
      padding-left: $input-btn-padding-x-xs/2 !important;

      & > i {
        margin-right: $input-btn-padding-x-xs/2 !important;
        font-size: $input-btn-icon-size-xs !important;
      }
    }
  }
}

//Custom buttons
.btn-spacer {
  display: block;
  height: rem(24px);
  width: rem(2px);
  background: $gray-300;
}

.btn-discount {
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(104px);
  width: rem(104px);
  background: $white;
  color: $gray-900;
  border: 1px solid $gray-200;
  box-shadow: none;
  overflow: visible;
  margin: 0 rem(8px);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background: $white;
    color: $gray-900;
    border-color: $gray-300;
    box-shadow: $box-shadow-sm;

    .badge {
      i {
        color: $gray-700;
      }
    }
  }

  .img {
    display: block;
    height: rem(64px);
    min-height: rem(64px);
    width: rem(64px);
    min-width: rem(64px);
    border-radius: 50%;
    background-color: $gray-200;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: rem(-5px);
    right: rem(-5px);
    border-radius: 50%;
    background: $gray-200;
    padding: rem(4px);

    i {
      font-size: rem(24px);
      color: $gray-600;
    }
  }
}

.chq-atc {
  display: inline-block;
  position: relative;

  &--button {
    background: transparent;
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;

    @extend .icon;
    @extend .icon-dropdown;

    &:before {
      font-family: "nexudus-icons-desktop" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      position: absolute;
      right: -12px;
      top: 5px;
    }

    &:hover {
      text-decoration: underline;
    }

    svg { 
      display: none;
    }
  }

  &--dropdown {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: .5px .5px 3px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    position: absolute;
    left: 0;
    text-align: left;
    white-space: nowrap;
    z-index: 1;

    a {
      display: block;
      text-decoration: none;
      padding: #{$dropdown-item-padding-x / 2} $dropdown-item-padding-x;
      font-weight: 400;
      color: $dropdown-link-color;

      &:hover {
        background-color: #eaeaea;
      }
    }
  }
}
