.product-totals {
  transition: bottom 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  bottom: -300px;

  &.fixed {
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    position: sticky;
    width: 100%;
    bottom: 0px;
    background: #f5f5f5;
    padding: 20px;
  }

  @include media-breakpoint-down(sm) {
    &.fixed {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
    }
  }
}
