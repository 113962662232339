﻿@import "utils/mixins";

/*#region Responsive Templates */
//@include media-breakpoint-only(xxl) {}
//@include media-breakpoint-only(xl) {}
//@include media-breakpoint-only(lg) {}
//@include media-breakpoint-only(md) {}
//@include media-breakpoint-down(sm) {}
/*#endregion */

//App
$site-mainmenu-contracted-width: rem(72px);
$site-mainmenu-expanded-width: rem(280px);
$site-member-header-height: rem(64px);
$site-sub-header-height: rem(48px);
$site-client-header-height: rem(80px);

// Color system
$bg-color: var(--bg-color);
$hover-color: var(--hover-color);
$text-color: var(--text-color);
$link-color: var(--link-color);

$white: #FFFFFF !default;
$gray-100: #F5F5F5 !default;
$gray-200: #EEEEEE !default;
$gray-300: #DDDDDD !default;
$gray-400: #CCCCCC !default;
$gray-500: #AEAEAE !default;
$gray-600: #999999 !default;
$gray-700: #666666 !default;
$gray-800: #444444 !default;
$gray-900: #111111 !default;
$black: #000000 !default;

// Primary
$primary--white: $white !default;
$primary--charcoal-black: #1c1510 !default;
$primary--teal: #20CAAB !default;

// Secondary
$secondary--red: #FF5C5C !default;
$secondary--pink: #FF7BD1 !default;
$secondary--blue-purple: #7770FF !default;
$secondary--yellow: #FFBB57 !default;

// Slate
$slate--dark-slate: #565366 !default;
$slate--mid-slate: #9E9AAB !default;
$slate--light-slate: #E3E2EE !default;
$slate--very-light-slate: #F0EFF4 !default;

$green: #28B95F !default;
$green-200: #D4F1DF !default;
$yellow: #DCB40A !default;
$orange: #F0783C !default;
$orange-200: #FCE4D8 !default;
$cyan: #14B4E6 !default;
$red: #F04B69 !default;
$red-200: #FCDBE1 !default;
$blue: #0064FA !default;
$purple: #5F00BA !default;
$pink: #EE589E !default;

$body-bg: $gray-100;

$theme-colors: ( "gray-100": $gray-100, "gray-200": $gray-200, "gray-300": $gray-300, "gray-400": $gray-400, "gray-500": $gray-500, "gray-600": $gray-600, "gray-700": $gray-700, "gray-800": $gray-800, "gray-900": $gray-900, "green": $green, "green-200": $green-200, "yellow": $yellow, "orange": $orange, "orange-200": $orange-200, "cyan": $cyan, "red": $red, "red-200": $red-200, "blue": $blue, "purple": $purple, "pink": $pink );

// Options
$enable-caret: false !default;

// Spacing
$spacers: ( 0: 0, 1: rem(1px), 2: rem(2px), 3: rem(3px), 4: rem(4px), 5: rem(5px), 6: rem(6px), 8: rem(8px), 10: rem(10px), 12: rem(12px), 16: rem(16px), 20: rem(20px), 24: rem(24px), 28: rem(28px), 32: rem(32px), 40: rem(40px), 48: rem(48px), 60: rem(60px), 64: rem(64px), 72: rem(72px), 84: rem(84px), 100: rem(100px) ) !default;
$sizes: ( 10: 10%, 20: 20%, 25: 25%, 30: 30%, 40: 40%, 50: 50%, 60: 60%, 70: 70%, 75: 75%, 80: 80%, 90: 90%, 100: 100%, auto: auto );

// Links
$link-decoration: none !default;
$link-hover-color: $link-color;
$link-hover-decoration: underline !default;

// Paragraphs
$paragraph-margin-bottom: rem(16px) !default;

// Grid breakpoints + containers + columns
$grid-breakpoints: ( xs: 0, sm: 320px, md: 768px, lg: 1014px, xl: 1349px, xxl: 1600px ) !default;
$container-max-widths: ( sm: 480px, md: 680px, lg: 920px, xl: 1252px, xxl: 1480px ) !default;

$grid-gutter-width: rem(24px) !default;

// Components
$border-radius: var(--border-radius) !default;
$border-radius-lg: $border-radius;
$border-radius-sm: calc(var(--border-radius) * 0.5) !default;

$box-shadow-sm: 0 2px 2px 0 rgba($gray-900,0.05);
$box-shadow: 0 2px 4px 0 rgba($gray-900,0.10), inset 0 -1px 0 0 rgba($gray-900,0.10);
$box-shadow-lg: 0 2px 4px 0 rgba($gray-900,0.04);

$component-active-color: $white !default;
$component-active-bg: $green !default;

$caret-width: rem(8px);
//$caret-vertical-align: $caret-width * .85 !default;
//$caret-spacing: rem(8px);

// Typography
$font-family-sans-serif: "GT-Walsheim", sans-serif !default;

$font-size-base: 1rem !default;

$font-weight-medium: 500 !default;

$h1-font-size: rscale(56px) !default;
$h2-font-size: rscale(40px) !default;
$h3-font-size: rscale(32px) !default;
$h4-font-size: rem(24px) !default;
$h5-font-size: rem(18px) !default;
$h6-font-size: rem(16px) !default;

$headings-margin-bottom: rem(16px) !default;

$small-font-size: rscale(12px) !default;

$hr-border-color: $gray-300 !default;
$hr-border-width: 2px !default;
$hr-margin-y: rem(40px);

// Tables
// Buttons + Forms

// Buttons
$input-btn-height-lg: rem(52px);
$input-btn-padding-y-lg: 0;
$input-btn-padding-x-lg: rem(16px);
$input-btn-font-size-lg: rem(16px);
$input-btn-icon-size-lg: rem(24px);

$input-btn-height: rem(40px);
$input-btn-padding-y: 0;
$input-btn-padding-x: rem(16px);
$input-btn-font-size: rem(14px);
$input-btn-icon-size: rem(24px);

$input-btn-height-sm: rem(32px);
$input-btn-padding-y-sm: 0;
$input-btn-padding-x-sm: rem(12px);
$input-btn-font-size-sm: rem(14px);
$input-btn-icon-size-sm: rem(24px);

$input-btn-height-xs: rem(24px);
$input-btn-padding-y-xs: 0;
$input-btn-padding-x-xs: rem(8px);
$input-btn-font-size-xs: rem(12px);
$input-btn-icon-size-xs: rem(16px);

$btn-box-shadow: $box-shadow;
$input-btn-focus-box-shadow: $box-shadow;
$btn-active-box-shadow: $box-shadow;
$input-btn-border-width: 0;
$btn-font-weight: 500;
$btn-line-height: 1;
$btn-block-spacing-y: 0;
$btn-border-radius: $border-radius;
$btn-transition: all 0.2s ease-in-out;


// Forms
$label-margin-bottom: rem(4px);

$input-padding-x: rem(12px);
$input-color: $gray-900;
$input-border-color: $gray-300;
$input-border-width: 1px;
$input-box-shadow: none;
$input-focus-border-color: $gray-400;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;

$input-height: $input-btn-height;
$input-height-sm: $input-btn-height-sm;
$input-height-lg: $input-btn-height-lg;

$custom-control-indicator-size: rem(20px);
$custom-control-indicator-border-color: $gray-300;

$custom-control-indicator-focus-border-color: $gray-400;
$custom-control-indicator-active-bg: $component-active-bg;
$custom-control-indicator-active-border-color: $component-active-bg;
$custom-control-indicator-active-box-shadow: none;

$custom-checkbox-indicator-border-radius: $border-radius-sm;
$custom-checkbox-indicator-icon-checked: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAyVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9m7n84AAAAQnRSTlMAAAECAwQFBgcICQsNDhIWKCo8UldbXl9iZG13fX6BgoeIi4yRkpWWm5yen6Slp6isra+wtLW7vcTL0tjs7e77/f515er9AAAAtUlEQVR42u3KA7pDUQwE4Lm2nm3bfsXsf1FNrauP5Rwm+aGUzNTDJURabBel4Or/X1wGrlfJrRJwu0b++sVwp07+RBiHSTLkdkl+hxiHK+ThgDsQ9xUgBa7J5LTnTkh+ekiDxpvMLtUWUy/k/+4gFcJ8kumtBkC7kd+zhQwI/U7mjwaMB3nvDWRCaFciXv0Xua815ECoZ2Iqcs5V5ELgiJQcA0UQe+L2UQJi42MT6bBklnBxYAML7RxQzD+vIgAAAABJRU5ErkJggg==);

$custom-radio-indicator-icon-checked: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAjVBMVEX///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+/pqIrAAAALnRSTlMACAsNDg8QERITFBYZGhweICEiIyQlJkZYY22SoqSlp6ipq6yur7vBw8TFxsfUU5z2IQAAAItJREFUeNrt0UWiwkAQBNCK5wsePO5a9z8e7jbs563bG5L0DXuqQMSk4AICxhW5FIlrSE/DUzounIoMXsTNO9c6z1eTkY7nVmRoYG9YkrGKF3q7Khq2BgWZqHhp1GwjTaC/jYsNvOG020j1vyQTE2/NtpF+Tma/H7/Rciv9E/hbRSa20Oe89Q9ESNIG+xsNRT19EEgAAAAASUVORK5CYII=);

$custom-switch-width: rem(40px);
$custom-switch-offset: rem(2px);
$custom-switch-bg-color: $gray-400;

$custom-select-padding-x: rem(12px);
$custom-select-indicator-padding: rem(22px);
$custom-select-bg-size: 13px 6px !default;
$custom-select-indicator: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAGCAMAAAAv8uSNAAAAElBMVEUAAAD///+ampqbm5uZmZmZmZm/hyzYAAAABXRSTlMAAGV6jySgALcAAAAsSURBVHjaNceBAQAABAKwoP9PBgAa1DBlKiIwesNpLXhxzqhTYzRsvGCGVQIWqQCBhy4tMwAAAABJRU5ErkJggg==);
$custom-select-box-shadow: $box-shadow-sm;
$custom-select-focus-box-shadow: $box-shadow-sm;
$custom-select-border-color: transparent;
$custom-select-focus-border-color: $gray-300;

// Form validation
// Navs
// Navbar

// Dropdowns
$dropdown-padding-x: rem(4px);
$dropdown-padding-y: rem(4px);
$dropdown-border-color: transparent;
$dropdown-box-shadow: $box-shadow;
$dropdown-min-width: rem(180px);

$dropdown-item-padding-x: rem(8px);
$dropdown-item-padding-y: 0;
$dropdown-link-color: $gray-600;
$dropdown-link-hover-color: $gray-900;
$dropdown-link-hover-bg: $gray-200;
$dropdown-link-active-color: $gray-900;
$dropdown-link-active-bg: $gray-300;

// Pagination
// Cards
// Tooltips

// Modals
$modal-inner-padding-x: rem(32px);
$modal-inner-padding-y: rem(40px);
$modal-inner-padding: $modal-inner-padding-x $modal-inner-padding-y;
$modal-dialog-margin-y-sm-up: rem(64px);
$modal-content-border-color: rgba($gray-900, 0.1);
$modal-backdrop-bg: $gray-900;
$modal-backdrop-opacity: .7;
$modal-header-border-width: 0;
$modal-xl: 960px !default;
$modal-lg: 740px !default;
$modal-md: 480px !default;
$modal-sm: 300px !default;

// Alerts
$alert-padding-y: rem(12px);
$alert-padding-x: rem(16px);

$alert-padding-y-sm: rem(4px);
$alert-padding-x-sm: rem(6px);
$alert-font-size-sm: rem(12px);

// Progress bars
$progress-height: rem(4px);
$progress-font-size: rem(12px);
$progress-bg: $gray-300 !default;
$progress-box-shadow: none;
$progress-bar-color: $text-color;
$progress-bar-bg: $bg-color;
