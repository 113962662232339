.rwe-find-a-space-dropdown {
    $block: &;
    position: relative;

    display: flex;
    flex-direction: row;

    z-index: 1;

    @include rwe-breakpoint-down(tablet) {
        justify-content: center;
    }
    
    &__arrow {
        position: relative;
        
        padding-right: 20px;
        
        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -1px;
            
            content: '';
            
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $primary--white;
        }

        .rwe-button--primary-dark.rwe-button--active &::after {
            border-top: 5px solid $primary--charcoal-black;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        padding: 24px;
        
        @include rwe-breakpoint-down(mobile) {
            flex-direction: column;
        }

        &-item {
            padding-right: 16px;
            
            @include rwe-breakpoint-down(mobile) {
                padding-right: 0;
                padding-bottom: 16px;
            }
            
            &--small {
                @include rwe-breakpoint-down(mobile) {
                    display: none;
                }
            }
            
            &--extra-small {
                width: 100%;
                
                @include rwe-breakpoint-up(tablet) {
                    display: none;
                }
            }
        }
    }
    
    & .rwe-dropdown-menu {
        @include rwe-breakpoint-down(mobile) {
            width: 100%;
        }
    }
}