.comments-form {
  .nx-mentions-input-wrapper {
    padding: 0 rem(12px);

    .form-control {
      border: none;
      font-size: rem(16px);

      .form-control__control {
        font-family: $font-family-sans-serif !important;

        .form-control__highlighter, .form-control__input {
          padding: rem(5px) 0 rem(4px) 0 !important;
          min-height: rem(32px) !important;
          font-size: rem(16px);
          @include placeholder(1, $gray-600, 400);
        }
      }
    }
  }
}