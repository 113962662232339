$rwe-grid-breakpoints: (
  mobile: 0,
  tablet: 768px,
  desktop: 1200px,
  large-desktop: 1440px,
  extra-large-desktop: 1920px
) !default;

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The `$rwe-grid-breakpoints` variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> rwe-breakpoint-next(sm)
//    md
//    >> rwe-breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> rwe-breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function rwe-breakpoint-next($name, $breakpoints: $rwe-grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> rwe-breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    576px
  @function rwe-breakpoint-min($name, $breakpoints: $rwe-grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }
  
  // Maximum breakpoint width. Null for the largest (last) breakpoint.
  // The maximum value is calculated as the minimum of the next one less 0.02px
  // to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    767.98px
  @function rwe-breakpoint-max($name, $breakpoints: $rwe-grid-breakpoints) {
    $next: rwe-breakpoint-next($name, $breakpoints);
    @return if($next, rwe-breakpoint-min($next, $breakpoints) - .02, null);
  }
  
  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
  // Useful for making responsive utilities.
  //
  //    >> rwe-breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    ""  (Returns a blank string)
  //    >> rwe-breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
  //    "-sm"
  @function rwe-breakpoint-infix($name, $breakpoints: $rwe-grid-breakpoints) {
    @return if(rwe-breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin rwe-breakpoint-up($name, $breakpoints: $rwe-grid-breakpoints) {
    $min: rwe-breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin rwe-breakpoint-down($name, $breakpoints: $rwe-grid-breakpoints) {
    $max: rwe-breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin rwe-breakpoint-between($lower, $upper, $breakpoints: $rwe-grid-breakpoints) {
    $min: rwe-breakpoint-min($lower, $breakpoints);
    $max: rwe-breakpoint-max($upper, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include rwe-breakpoint-up($lower, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include rwe-breakpoint-down($upper, $breakpoints) {
        @content;
      }
    }
  }
  
  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin rwe-breakpoint-only($name, $breakpoints: $rwe-grid-breakpoints) {
    $min: rwe-breakpoint-min($name, $breakpoints);
    $max: rwe-breakpoint-max($name, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include rwe-breakpoint-up($name, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include rwe-breakpoint-down($name, $breakpoints) {
        @content;
      }
    }
  }
  