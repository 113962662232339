.rwe-card {
    $block: &;
    $space: 16px;
    $half-space: 8px;

    position: relative;

    &__image {
        height: 176px;

        margin-bottom: $space;

        & > img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
    
    &__heading {
        @extend .rwe-h4-alt;

        margin-bottom: $space;

        color: $primary--charcoal-black;
        
        a:hover & {
            text-decoration: underline;
        }
    }

    &__icon-and-text-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    &__text-content {
        padding: 0 24px 28px 24px;
    }

    &--office {

        #{$block}__image {
            height: unset;
        }

        &__address {
            @extend .rwe-p1;

            display: flex;
            flex-direction: column;

            margin-bottom: $space;

            color: $slate--mid-slate;
            
            & a:hover {
                text-decoration: underline;
            }
        }

        &__number {
            @extend .rwe-p1;

            color: $slate--mid-slate;
            
            & a:hover {
                text-decoration: underline;
            }
        }

        &__icon {
            margin-right: $space;

            color: $slate--mid-slate;
        }
    }

    &--solution {

        #{$block}__image {
            height: unset;
        }

        &__heading {
            margin-bottom: $half-space;
        }

        &__sub-heading {
            @extend .rwe-p2;

            margin-bottom: $space;

            color: $slate--mid-slate;
            
            a:hover & {
                text-decoration: underline;
            }
        }

        &__copy {
            @extend .rwe-p1;

            margin-bottom: $space;

            color: $slate--dark-slate;
            
            a:hover & {
                text-decoration: underline;
            }
        }
    }

    &--article {
        border-radius: 5px;

        overflow: hidden;

        background-color: $primary--white;

        box-shadow: 0px 7px 20px -6px rgba(68, 66, 95, 0.2);

        transition: box-shadow .5s;

        &:hover {
            box-shadow: 0px 7px 30px -6px rgba(68, 66, 95, 0.4);
        }

        &__category-label {
            position: absolute;
            top: 0;
            right: 0;

            z-index: 1;

            padding: 12.5px 16px;

            color: $primary--white;

            border-bottom-left-radius: 5px;
        }

        &__heading {
            @include line-clamp(2);

            color: $primary--charcoal-black;
        }

        &__byline {
            @include line-clamp(1);

            color: $slate--mid-slate;

            margin-top: 8px;
        }

        &__copy {
            @include line-clamp(3);

            margin-top: 16px;
        }
    }

    &--member {
        display: flex;
        flex-direction: column;

        height: 432px;

        border-radius: 5px;

        overflow: hidden;

        background-color: $primary--white;

        box-shadow: 0px 7px 20px -6px rgba(68, 66, 95, 0.2);

        &__image {
            min-height: 1px;
            flex: 1;
        }

        &__logo {
            height: 35px;
            
            > img {
                height: 100%;
            }
        }

        &__body-content {
            padding: 16px;
        }

        &__name {
            @include line-clamp(1);

            margin-top: 16px;

            color: $slate--dark-slate;
        }

        &__member-title {
            @include line-clamp(1);

            margin-top: 8px;

            color: $slate--dark-slate;
        }
        
        &__copy {            
            margin-top: 16px;

            color: $primary--charcoal-black;
        }
    }
}

.rwe-workspace-card {
    $block: &;

    display: flex;

    flex-direction: row;

    @include rwe-breakpoint-down(tablet) {
        flex-direction: column;
    }

    border-radius: 5px;

    overflow: hidden;

    background-color: $primary--white;

    box-shadow: 0px 7px 20px -6px rgba(68, 66, 95, 0.2);

    transition: box-shadow .5s;

    &:hover {
        box-shadow: 0px 7px 30px -6px rgba(68, 66, 95, 0.4);
    }

    &--within-card-grid {
        flex-direction: column;
        
        #{$block}__image {
            flex: unset;
            height: 176px;
        }

        #{$block}__details {
            flex: unset;
            
            height: 188px;
            
            padding: 16px 24px;
        }

        #{$block}__label {
            left: unset;
            right: 0;
            
            border-bottom-right-radius: unset;
            border-bottom-left-radius: 5px;
        }
    }

    &__image {
        position: relative;

        @include rwe-breakpoint-up(desktop) {
            flex: 1;
            height: 242px;
        }

        @include rwe-breakpoint-down(tablet) {
            height: 176px;
        }
    }
    
    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        
        padding: 24px 16px;
        
        @include rwe-breakpoint-up(desktop) {
            flex: 1;
        }

        @include rwe-breakpoint-down(tablet) {
            height: 188px;

            padding: 16px 24px;
        }
    }
    
    &__label {
        position: absolute;
        top: 0;
        
        
        @include rwe-breakpoint-up(desktop) {
            left: 0;
        }
        
        @include rwe-breakpoint-down(tablet) {
            right: 0;
        }
        
        padding: 12.5px 16px;
        
        color: $primary--white;
        
        
        @include rwe-breakpoint-up(desktop) {
            border-bottom-right-radius: 5px;
        }
        
        @include rwe-breakpoint-down(tablet) {
            border-bottom-left-radius: 5px;
        }
    }
    
    &__name {
        @include line-clamp(1);
        
        color: $primary--charcoal-black;
    }
    
    &__site-name {
        @include line-clamp(1);
        
        color: $slate--dark-slate;
        
        margin-top: 8px;
    }

    &__transport-link {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 8px;

        color: $slate--dark-slate;

        &:last-child {
            margin-bottom: 0;
        }

        &-icon {
            width: 24px;
            height: 24px;

            margin-right: 8px;
        }
    }

    &__capacity-and-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__capacity {
        @include line-clamp(1);

        color: $slate--dark-slate;
    }

    &__price {
        @include line-clamp(1);

        color: $slate--dark-slate;
    }
}

.rwe-card-grid {
    .rwe-flex-grid--carousel {
        &-tablet {
            @include rwe-breakpoint-only(tablet) {
                & .rwe-flex-grid__item {
                    min-width: calc(100% - 32px);
                }
            }
        }
        &-mobile {
            @include rwe-breakpoint-down(mobile) {
                & .rwe-flex-grid__item {
                    min-width: calc(100% - 32px);
                }
            }
        }
    }
}