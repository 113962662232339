﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  1.00 Tables
-----------------------------------------
*/

.table {
  th.rotate {
    transform: rotate(-30deg) translateX(15px) translateY(18px);
    transform-origin: bottom left;
    white-space: nowrap;
  }

  th,
  td {
    font-size: rem(14px);
    padding: rem(10px) rem(16px);
    border: none;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &[valign='middle'] {
      vertical-align: middle !important;
    }
  }

  > thead {
    > tr > th {
      font-weight: 500;
      border: none;
    }
  }

  &-striped {
    tr {
      border: none;
    }

    tbody {
      tr:nth-of-type(odd) {
        background: $white;
      }

      tr:nth-of-type(even) {
        background: $gray-100;
      }
    }
  }

  &--settings {
    > tbody {
      > tr {
        border-top: none;

        &.tr-parent {
          border-bottom: 2px solid $gray-100;

          > th,
          > td {
            padding-top: rem(12px);
            background-color: $white;
          }
        }

        &.tr-child {
          > td {
            display: none;
            padding-bottom: rem(24px);
            font-size: rem(14px);
            background-color: $gray-100;
          }

          &.open {
            > td {
              display: table-cell;
            }
          }
        }
      }
    }

    &__handler {
      .caret {
        display: block;
        transform-origin: center center;
        transform: rotate(0);
        margin-right: rem(8px);
        padding-left: rem(4px);
        font-size: rem(18px);
      }

      small {
        font-size: rem(14px) !important;
        line-height: 1.4 !important;
        margin-top: 0 !important;
        padding-left: rem(30px) !important;
        color: $gray-700 !important;
      }

      &.open {
        .caret {
          transform: translateY(-2px) rotate(45deg);
        }
      }
    }

    &__title {
      display: inline-flex;
      align-items: center;
      position: relative;
      text-decoration: none !important;
    }

    &__expiration {
      display: block;
      margin-left: 20px;
      font-size: rem(14px);
      color: $gray-900;
    }

    &__details-list {
      margin: 0 0 0 20px;
      padding: 0;
      list-style-type: none;

      > li {
        margin: 6px 12px;

        small {
          margin: 0 !important;
        }
      }
    }

    &__payment-options {
      .btn {
        display: flex;
      }

      .btn + .btn {
        margin-top: rem(4px);
      }
    }
  }

  &--kpi {
    font-size: rem(14px);

    > thead {
      > tr > th {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    > tbody {
      > tr {
        > th,
        > td {
          padding-top: rem(8px);
          padding-bottom: rem(8px);
          vertical-align: middle;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        > th {
          font-weight: 500;
        }

        > td {
        }
      }
    }
  }

  &--sortable {
    > thead {
      > tr > th {
        cursor: pointer;

        i {
          display: none;
        }

        &:hover,
        &:focus {
          i {
            display: inline;
          }
        }

        &.active {
          background-color: inherit;

          i {
            display: inline;
          }
        }
      }
    }
  }

  &--receipt {
    margin: 0;
    border: 0;

    th {
      font-weight: 400;
      border-bottom: 0;
    }

    th,
    td {
      padding: 15px 0 !important;
    }

    > tbody {
      > tr > th,
      > tr > td {
        font-weight: 500;
      }

      > tr > th {
        font-size: $font-size-base;
      }
    }

    > tfoot {
      > tr > td > b {
        display: block;
        padding: 15px 0;
        font-size: $font-size-base;
        text-transform: uppercase;
      }
    }
  }

  &--invoices {
    margin-bottom: 0;

    > thead {
      > tr > th {
        font-weight: 400;
        color: $gray-900;
        background-color: $gray-200;
        border-bottom-width: 0;
      }
    }

    > tbody {
      > tr {
        > th,
        > td {
          padding-top: 15px;
          padding-bottom: 15px;
          vertical-align: middle;
        }

        &:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }

        &:last-child {
          > th,
          > td {
            padding-bottom: 0;
          }
        }
      }
    }

    i {
      font-size: rem(24px);
    }
  }
}

@media (max-width: 767px) {
  .table-wrap table,
  .table-wrap thead,
  .table-wrap tbody,
  .table-wrap tfoot,
  .table-wrap th,
  .table-wrap td,
  .table-wrap tr {
    display: block;
  }

  .table-wrap thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-wrap td {
    border: none;
    position: relative;
    padding-left: 35% !important;
    white-space: normal;
    text-align: left;
  }

  .table-wrap td:before {
    position: absolute;
    top: 8px;
    left: 15px;
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
  }

  .table-wrap td:before {
    content: attr(data-title);
  }

  .table tbody tr {
    padding-top: rem(16px);
    padding-bottom: rem(16px);
  }

  .table tbody tr td {
    border: none;
    padding-top: rem(8px) !important;
    padding-bottom: rem(8px) !important;
  }

  .table tfoot tr {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table tfoot tr td {
    border: none;
    padding-top: rem(8px) !important;
    padding-bottom: 0 !important;
  }

  .table--sm--no-thead {
    thead {
      display: none;
    }

    td {
      padding-left: rem(16px) !important;

      &:before {
        display: none;
      }
    }
  }
}
