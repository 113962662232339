.rwe-preview-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 10px 0;

    background-color: $secondary--pink;
    color: $primary--white;

    &__text {
        margin-right: 96px;

        @include rwe-breakpoint-down(mobile) {
            display: none;
        }
    }

    &__link {
        text-decoration: underline;
        text-decoration-color: currentColor;
    }
}

.rwe-preview-banner-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;
}