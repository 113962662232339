.rwe-structured-text {
    &__icon {
        height: 80px;
        aspect-ratio: 1;

        margin-bottom: 16px;

        object-fit: contain;
    }

    &__image-with-caption {
        margin: 16px 0;
    }

    &__newsletter-signup {
        margin: 72px 0;

        @include rwe-breakpoint-down(mobile) {
            margin: 48px 0;
        }

        .rwe-cta-box {
            width: 100%;

            &__content {
                padding: 32px;
            }
        }
    }

    &__quote {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        margin: 72px 0;

        @include rwe-breakpoint-down(mobile) {
            margin: 48px 0;
        }
    }

    &__highlighted-text {
        padding: 16px 16px 8px 16px;
        margin-bottom: 16px;
    }

    &__button {
        margin-bottom: 8px;
    }
}