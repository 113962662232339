.rwe-filter {
    position: relative;

    display: flex;
    flex-direction: column;

    &__dropdown {
        position: relative;
        top: 8px;

        display: flex;
    }
}

.rwe-tablet-filters-section {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    z-index: 1;

    padding: 24px 0;

    @include rwe-breakpoint-down(mobile) {
        display: none;
    }

    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__count {
        margin-left: 8px;
        color: $slate--dark-slate;
    }
}

.rwe-tablet-filters {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include rwe-breakpoint-down(mobile) {
        display: none;
    }

    .rwe-filter {
        margin-right: 8px;
    }

    &__clear-button {
        margin-left: 32px;

        button {
            color: $primary--charcoal-black;
            text-decoration: underline;
            text-decoration-color: currentColor;
        }
    }
}

.rwe-mobile-filters-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 12px 0;

    @include rwe-breakpoint-up(tablet) {
        display: none;
    }

    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
  }

    &__count {
        color: $slate--dark-slate;
    }
}

.rwe-mobile-filters {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include rwe-breakpoint-up(tablet) {
        display: none;
    }

    .rwe-filter {
        margin-right: 8px;
    }

    &__clear-button {
        margin-left: 32px;
    }

    &-drawer {

        &__header {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__title {
            color: $primary--charcoal-black;
        }

        &__clear-button {
            margin-left: 16px;

            button {
                color: $primary--charcoal-black;
                text-decoration: underline;
                text-decoration-color: currentColor;
            }

        }
    }
}
