.rwe-tab {
    position: relative;

    text-align: center;

    color: $primary--charcoal-black;

    &__text {
        padding-bottom: 8px;
    }

    &__line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4px;

        background-color: $primary--teal;

        border-radius: 4px;

        @include rwe-breakpoint-down(mobile) {
            border-radius: 0;
        }
    }
}

.rwe-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid $slate--light-slate;

    @include rwe-breakpoint-down(mobile) {
        justify-content: space-evenly;
    }

    &__tab {
        position: relative;
        top: 1px;

        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        @include rwe-breakpoint-down(mobile) {
            margin-right: 0;

            flex: 1;
        }
    }
}