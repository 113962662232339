.rwe-icon {
    position: relative;

    &__badge {
        position: absolute;
        width: 12px;
        height: 12px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border-radius: 3px;

        font-size: 8px;
        
        &--top-right {
            top: 1px;
            right: 0;
        }

        &--red {
            background-color: $secondary--red;
            color: $primary--white;

        }
    }
}