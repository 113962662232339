﻿@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  3.00 Settings
-----------------------------------------
*/

.settings {
  &__nav {
    position: sticky;
    top: 0;
  }

  &__content {
    padding-bottom:rem(48px);

    fieldset {
      position: relative;
      display: block;

      &:not(:last-child) {
        padding: 0 0 rem(24px) 0;
        margin: 0 0 rem(32px) 0;
        border-bottom: 1px solid $hr-border-color;
      }

      .section__title {
        position: relative;
        display: block;
        font-weight: 700;
        font-size: rem(18px);
        line-height: 1.33;
        margin-bottom: rem(20px);
      }

      small {
        display: block;
        font-size: rem(12px);
        line-height: 1.3;
        color: $gray-600;
        margin: 0;
        margin-top: rem(8px);
      }
    }

    .profile-form {
      .table {
        .tag {
          display: inline-flex;

          &.red {
            background-color: #f8d7da;
          }
      
          &.green {
            background-color: #d4f1df;
          }
        }
      }
    }

    .team-members-permissions {
      .table {
        table-layout: fixed;

        thead {
          tr {
            @include media-breakpoint-down(md) {
              position: absolute;
              top: -9999px;
              left: -9999px;
              padding-top: rem(16px);
              padding-bottom: rem(16px);
            }

            th {
              width: 80px;

              &.name-col {
                width: 100px;
              }
      
              &.access-card-col {
                width: 130px;
              }
      
              &.access-col {
                width: 50px;
              }

              @include media-breakpoint-up(xl) {
                width: 20px;

                &.name-col {
                  width: 100px;
                }
        
                &.access-card-col {
                  width: 70px;
                }
        
                &.access-col {
                  width: 40px;
                }
              }
            }
          }
        }

        tbody {
          tr {
            @include media-breakpoint-down(md) {
              padding-top: rem(16px);
              padding-bottom: rem(16px);
            }
          }
        }
  
        tr, td {
          @include media-breakpoint-down(md) {
            display: block;
          }
        }

        td {
          @include media-breakpoint-down(md) {
            position: relative;
            padding-left: 35% !important;
            white-space: normal;
            text-align: right;

            div {
              justify-content: flex-end;
            }
            
            &:before {
              content: attr(data-title);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 15px;
              width: 45%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              font-weight: 500;
            }
          }

          &.access-cell {
            @include media-breakpoint-down(lg) {
              padding: 0;
            }

            @include media-breakpoint-down(md) {
              padding: rem(10px) rem(16px);
            }
          }

          .avatar, .access-text {
            @include media-breakpoint-down(lg) {
              display: none;
            }

            @include media-breakpoint-down(md) {
              display: inline;
            }
          }

          .icon {
            @include media-breakpoint-down(lg) {
              color: $gray-600;
            }

            @include media-breakpoint-down(md) {
              color: $gray-900;
            }
          }
        }
  
        .react-tag-input__input {
          overflow: hidden;
        }
  
        .access {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
}
