@import '../../../fonts/Icomoon/desktop/style.scss';

$navbar-heightish--desktop: 97px;
$navbar-heightish--tablet: 146px;
$navbar-heightish--mobile: 56px;

@mixin nav-break {
  @media (max-width: 1399.98px) {
    @content
  }
}

@mixin nav-break-only {
  @media (min-width: 768px) and (max-width: 1399.98px)  {
    @content
  }
}

.rwe-navbar-container {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;

    transition: all .5s;

    &--hidden {
        margin-top: -$navbar-heightish--desktop;

        @include nav-break-only {
            margin-top: -$navbar-heightish--tablet;
        }

        @include rwe-breakpoint-down(mobile) {
            margin-top: -$navbar-heightish--mobile;
        }
    }

    &--transparent {

        .rwe-navbar {
            background-color: transparent;
        }
    }

    .rwe-drawer, .rwe-drawer-backdrop {
        position: fixed;
    }

    & + .rwe-page-content .rwe-header-center {
        padding-top: $navbar-heightish--desktop;

        @include nav-break {
            padding-top: $navbar-heightish--tablet;
        }

        @include rwe-breakpoint-down(mobile) {
            padding-top: $navbar-heightish--mobile;
        }

    }
}

.rwe-responsive-navbar {
    @include rwe-breakpoint-down(mobile) {
        .rwe-navbar {
            display: none;
        }
    }

    @include rwe-breakpoint-up(tablet) {
        .rwe-navbar--mobile, .rwe-drawer, .rwe-drawer-backdrop {
            display: none;
        }
    }
}

.rwe-page-content {
    display: flex;
    flex-direction: column;

    min-height: 100vh;

    padding-top: $navbar-heightish--desktop;
    overflow: hidden;

    .rwe-header-center {
        margin-top: -$navbar-heightish--desktop;
    }

    *:first-child > .rwe-section,
    .rwe-breadcrumb-list-section + *:first-child > .rwe-section, .rwe-breadcrumb-list-section + .rwe-section:first-child {
        padding-top: 24px;

        &__line {
            display: none;
        }
    }

    @include nav-break {
        padding-top: $navbar-heightish--tablet;

        .rwe-header-center {
            margin-top: -$navbar-heightish--tablet;
        }
    }

    @include rwe-breakpoint-down(mobile) {
        padding-top: $navbar-heightish--mobile;

        .rwe-header-center {
            margin-top: -$navbar-heightish--mobile;
        }
    }
}



.rwe-navbar {
    display: flex;
    justify-content: center;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 24px 32px;

        width: 1600px;
        max-width: 100%;

        @include nav-break {
            flex-wrap: wrap;
        }
    }

    &__logo {
        margin-right: 32px;

        @include nav-break {
            flex: 1;
        }
    }

    &__navigation {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: 100%;

        @include nav-break {
            flex: unset;
            width: 100%;
            height: unset;
            order: 1;

            margin-top: 24px;
        }

        &-item {
            display: flex;
            position: relative;
            margin-right: 40px;

            &:last-child {
                margin-right: 0;
            }

            &-text {
                display: flex;
                flex-direction: row;
                align-items: center;

                white-space: pre;
                user-select: none;

                color: $primary--charcoal-black;

                &:hover {
                    color: $slate--mid-slate;

                    cursor: pointer;
                }

                &--with-arrow-on-right {
                    position: relative;

                    padding-right: 20px;

                    &:hover {

                        &::after {
                            border-top: 5px solid $slate--mid-slate;
                        }
                    }

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        margin-top: -1px;

                        content: '';

                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid $primary--charcoal-black;
                    }
                }
            }

            &-left-accessory-container {
                display: flex;
                align-items: center;
                margin-right: 12px;
              }
        }
    }

    &__right-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        margin-left: 16px;

        &-cta-container > :last-child {
            margin-right: 24px;
        }

        @include nav-break {
            flex: 1;
        }
    }
}

.rwe-navbar--mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 12px 16px;

    width: 100%;

    background-color: $primary--white;

    transition: all .5s;

    &__left-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        flex: 1;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        flex: 1;

        margin: 0 16px;
    }

    &__right-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        flex: 1;

        &-item {
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__hamburger-icon {
        @include icon($icon-menu);
        font-size: 200%;
    }

    &__user-icon {
        @include icon($icon-user-circle);
        font-size: 200%;
    }
}
