.rwe-location-site-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        background-color: $primary--white;

        padding-top: 24px;

        &-text > h1 {
            font-size: 288px;
            line-height: 110%;
            color: #000;

            margin-left: -10px;

            @include rwe-breakpoint-only(desktop) {
                font-size: 200px;

                margin-left: -7px;
            }

            @include rwe-breakpoint-only(tablet) {
                font-size: 144px;

                margin-left: -5px;
            }

            @include rwe-breakpoint-down(mobile) {
                font-size: 80px;

                margin-left: -3px;
            }
        }
    }

    &__address-and-phone-number {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 24px 0;

        @include rwe-breakpoint-down(mobile) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__address {
        color: $slate--dark-slate;

        @include rwe-breakpoint-down(mobile) {
            margin-bottom: 16px;
        }

        &-dot {
            width: 6px;
            height: 6px;
            border-radius: 100%;

            margin: 0 16px;

            background-color: $slate--mid-slate;

            @include rwe-breakpoint-down(mobile) {
                display: none;
            }
        }
    }

    &__phone-number {
        color: $primary--teal;
        
        a {
            text-decoration-color: currentColor;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__reusable-components {
        width: 100%;
    }
}

.rwe-location-site-page__email-address{
    color: #20caab;
}

.rwe-location-site-page__address{
    color: #20caab !important;
}

@media (max-width: 650px) {
    .tf-v1-popup{
      display: none !important;
    }
    body{
        overflow: auto !important;
    }
  }