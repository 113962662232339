.rwe-article-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding-bottom: 72px;

    background-color: $primary--white;
    
    @include rwe-breakpoint-only(tablet) {
        padding-bottom: 48px;
    }

    @include rwe-breakpoint-down(mobile) {
        padding-bottom: 24px;
    }

    &__content-container {
        display: flex;
        flex-direction: row;

        @include rwe-breakpoint-down(tablet) {
            flex-direction: column-reverse;
        }
    }
    &__image {
        // When changing this, check that the DatoCMS query to fetch any image that could be here is transformed by Imgix appropriately
        flex: 1;
        
        // When changing this, check that the DatoCMS query to fetch any image that could be here is transformed by Imgix appropriately
        height: 440px;

        margin-right: 48px;

        @include rwe-breakpoint-only(tablet) {
            margin: 0 -72px;
        }

        @include rwe-breakpoint-down(mobile) {
            margin: 0 -16px;
        }
    }

    &__heading {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        @include rwe-breakpoint-down(tablet) {
            margin-top: 16px;
            margin-bottom: 24px;
        }
    }

    &__title {
        color: $primary--charcoal-black;
    }

    &__author-and-date {
        display: flex;
        flex-direction: row;

        margin-top: 24px;

        color: $slate--dark-slate;
    }

    &__date {
        border-left: 3px solid $slate--dark-slate;

        padding-left: 16px;
        margin-left: 16px;
    }

    &__categories {
        margin-top: 32px;
    }
}