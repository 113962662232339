.rwe-embed {
    height: 100%;

    > iframe {
        width: 100%;
        height: 100%;
        
        border: 0;
    }
}

.rwe-embed-with-contact-details-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    background-color: $primary--white;

    &__content-container {
        display: flex;
        flex-direction: row;

        padding: 72px 0;
        
        @include rwe-breakpoint-down(tablet) {
            flex-direction: column;
            padding: 48px 0;
        }
    }

    &__embed {
        width: 100%;
        height: 614px;
        
        margin-right: 48px;
        
        @include rwe-breakpoint-down(tablet) {
            height: 631px;

            margin-right: 0;
            margin-bottom: 48px;
        }
    }

    &__ebox {
        width: 100%;

        &-heading {
            margin-bottom: 16px;
        }

        &-icon {
            margin-right: 16px;
        }
        
        &-phone-number {
            display: flex;
            flex-direction: row;
            
            margin-bottom: 32px;
        }
        
        &-email-address {
            display: flex;
            flex-direction: row;
            
            margin-bottom: 32px;
        }

        &-address {
            display: flex;
            flex-direction: row;
        }

        > .rwe-cta-box {
            width: 100%;
        }
    }
}