.course {
  hr {
    margin: rem(24px) 0;
    border-top: 1px solid $gray-200;
  }
}

.course-page-details {
  .fr-video {
    @extend .embed-responsive;
    @extend .embed-responsive-16by9;
  }

  h5 {
    font-weight: bold;
  }
}

.course-page-access {
  .price {
    font-size: rem(24px);
    font-weight: 500;
  }
}

.course-page-attendees {
  span {
    font-weight: 500;
  }

  .attendees-number {
    font-size: rem(32px);
  }

  .students {
    font-size: rem(16px);
  }

  .view-all {
    font-size: rem(14px);
  }

  .attendee-name {
    font-size: rem(16px);
  }

  .attendee-company {
    font-size: rem(14px);
  }

  .avatar {
    width: rem(40px);
    height: rem(40px);
  }
}

.course-outline-list {
  list-style: none;
  padding-left: rem(20px);

  .icon {
    font-size: rem(24px);
    color: $bg-color;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.completed-course {
  .completed-card {
    padding: rem(48px) rem(106px);
  }
}

.lesson-details {
  .lesson-navigation-container {
    @include media-breakpoint-down(md) {
      display: none;
    }

    .nav-header {
      .title {
        margin-bottom: 0;
      }
    }
  }

  hr {
    margin: rem(24px) 0;
    border-top: 1px solid $gray-200;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .hidden-md {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .completion {
    font-size: rem(14px);
    font-weight: 500;
  }

  .fr-video {
    @extend .embed-responsive;
    @extend .embed-responsive-16by9;
  }

  &__sticky-nav {
    position: sticky;
    top: 0;
    height: 100vh;

    @include media-breakpoint-down(md) {
      position: relative;
      height: auto;
    }

    .view-discussion {
      .help-text {
        margin-bottom: 0;

        @include media-breakpoint-only(lg) {
          margin-bottom: rem(24px);
        }
      }

      .btn {
        padding-left: 0;
        padding-right: rem(16px);

        .icon {
          font-size: rem(24px);
        }
      }
    }
  }

  &__metadata {
    .requirement {
      background-color: #f8f0ce;
      padding: rem(2px) rem(12px) rem(2px) rem(4px);
      border-radius: rem(8px);
    }
  }

  &__image {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding-top: 54%;
    margin-bottom: rem(48px);
  }

  &__contents {
    padding: 0 rem(36px) rem(24px) rem(36px);

    @include media-breakpoint-up(md) {
      padding: 0 rem(106px) rem(48px) rem(106px);
    }

    img {
      @extend .img-fluid;
    }
  }

  &__actions {
    padding: rem(36px) rem(24px);

    @include media-breakpoint-up(md) {
      padding: rem(48px) rem(106px);
    }

    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }

  .vid-visual-progress {
    height: 5px;
    background-color: $gray-400;

    .progress-bar.gray {
      background-color: $gray-400;
    }
  }
}