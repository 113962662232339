.rwe-testimonial-single {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @include rwe-breakpoint-only(mobile) {
        flex-direction: column;

        margin: 0 -16px;
    }

    &__image {
        width: 600px;
        height: 400px;

        @include rwe-breakpoint-only(tablet) {
            width: 600px;
            height: 294px;
        }

        @include rwe-breakpoint-only(mobile) {
            width: 100%;
            height: 250px;
        }
    }
    &__testimonial {
        position: relative;
        margin-top: 144px;
        margin-left: -100px;

        width: 550px;

        padding: 32px;

        @include rwe-breakpoint-only(tablet) {
            margin-top: 220px;
            margin-left: -330px;

            width: 700px;
        }

        @include rwe-breakpoint-only(mobile) {
            margin-top: 0;
            margin-left: 0;
            width: 100%;
        }

    }
    &__testimonial-logo {
        height: 36px;
        width: 82px;
        margin-bottom: 24px;
    }
    &__testimonial-text {
        margin-bottom: 8px;
    }
}

.rwe-testimonial-grid-section {
  background-color: $slate--very-light-slate;

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px -6px rgba(96, 92, 132, 0.2);
    border-radius: 5px;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__card-content {
    margin-top: 24px;
  }

  &__quotee {
    margin-top: 24px;
  }
}
