.rwe-quote {
    position: relative;
    
    z-index: 1;
    
    &__icon {
        position: absolute;
        top: 0;
        left: 0;

        z-index: -1;
    }

    &__text {
        width: 100%;
        max-width: 400px;
        
        padding-top: 16px;
        padding-left: 24px;

        color: $primary--charcoal-black;
    }
}