﻿@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  3.00 Guest
-----------------------------------------
*/

.home-banner {
  overflow-x: hidden;
  background: $white;

  .container {
    display: flex;
    align-items: stretch;

    @include media-breakpoint-down(md) {
      flex-direction: column !important;
    }
  }

  &__text {
    flex: 0 0 50%;
    padding: rem(80px);
    padding-left: 0;

    @include media-breakpoint-down(md) {
      order: 2;
      flex: 1 0 auto;
      padding: rem(64px) 0;
    }

    & > h1 {
      margin-bottom: rem(24px);
    }

    & > p {
      font-weight: 500;
      font-size: rem(18px);
      line-height: 1.33;
      margin-bottom: rem(48px);
    }

    & > .btn {
      width: 100%;
      margin-bottom: rem(16px);
    }

    & > .sign-in {
      text-align: center;
      font-weight: 500;

      span {
        color: $gray-500;
        padding-right: rem(4px);
      }

      a {
        color: $gray-900;
        text-decoration-color: currentColor;

        &:hover, &:active, &:focus {
          color: $link-color;
        }
      }
    }
  }

  &__image {
    position: relative;
    flex: 0 0 50%;

    @include media-breakpoint-down(md) {
      order: 1;
      flex: 1 0 auto;
      height: rem(340px);
    }

    @include media-breakpoint-down(sm) {
      height: rem(280px);
    }

    .img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50vw;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-down(md) {
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
      }
    }
  }

  &--virtual {
    .home-banner__text {
      padding-top: rem(128px);
      padding-bottom: rem(128px);

      @include media-breakpoint-down(md) {
        padding-top: rem(96px);
        padding-bottom: rem(72px);
      }

      @include media-breakpoint-down(sm) {
        padding-top: rem(48px);
        padding-bottom: rem(0);
      }

      & > .sign-in {
        text-align: left;
      }
    }
  }
}

.home-top-claim-features-section {
  padding: rscale(140px) 0;

  &__claim {
    text-align: center;

    h2 {
      margin: 0 auto;
      max-width: rem(1100px);
    }

    a {
      display: inline-block;
      margin-top: rem(24px);
      font-size: $h4-font-size;
    }
  }

  &__features {
    padding-top: rscale(64px);

    .features-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }

      li {
        padding: 0 rem(16px);

        @include media-breakpoint-down(sm) {
          padding: rem(24px);
        }

        a {
          display: block;

          .img-wrapper {
            display: block;
            text-align: center;
            height: rem(72px);

            svg {
            }
          }

          h4 {
            margin: 0;
            color: $gray-900;
            text-align: center;
            @include transition();
          }

          &:hover, &:active, &:focus {
            h4 {
              color: $link-color;
            }
          }
        }
      }
    }
  }
}

.home-services-section {
  background: $white;
  padding: rscale(130px) 0 rscale(100px) 0;

  .services-list__item {
    padding-bottom: rscale(40px);

    i {
      font-size: rem(40px);
    }

    h4 {
      margin: rem(24px) 0 rem(8px) 0;
    }

    p {
      font-size: rem(18px);
      line-height: 1.33;
    }
  }

  &--virtual {
  }
}

.home-plans-section {
  padding: rscale(100px) 0;

  .nav-tabs {
    justify-content: center;
    margin-bottom: rem(48px);

    .nav-item {
      .nav-link {
        font-size: rem(36px);
        background: transparent !important;
        color: $gray-900 !important;
        opacity: 0.2;

        &:hover, &:active, &:focus {
          opacity: 0.6;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.home-products-section {
  padding-bottom: rscale(100px);
}

.home-locations-section {
  padding: rscale(110px) 0 rscale(100px) 0;
  background: $white;
}

.about-page-content, .custom-page-content {
  background: $white;
  padding: rscale(80px) 0 rscale(100px) 0;
  border-bottom: 1px solid $body-bg;

  .intro {
    h1, h2, h3 {
      text-align: center;
      max-width: rscale(840px);
      margin: 0 auto;
      margin-bottom: rem(24px);
    }

    h4 {
      text-align: center;
      max-width: rscale(840px);
      margin: 0 auto;
      margin-bottom: rscale(80px);
      color: $bg-color;
    }

    .img {
      background-color: $gray-200;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      max-width: rscale(1040px);
      padding-top: 30%;
      margin: 0 auto;
      margin-bottom: rscale(80px);
      border-radius: $border-radius;
    }
  }

  .content {
    margin: 0 auto;
    max-width: rscale(840px);

    .fr-rounded {
      border-radius: $border-radius;
    }

    img {
      max-width: 100%;
    }

    blockquote {
      border-left: rem(4px) solid $bg-color;
      padding-left: rem(8px);
      line-height: 1.33;
      margin-bottom: 0;
    }

    table {
      tr {
        td {
          padding: rem(8px);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.banner-carousel {
  padding: rscale(100px) 0;

  .carousel {
    max-width: rscale(1040px);
    margin: 0 auto;
  }
}

.about-page-our-team {
  padding: rscale(100px) 0;

  .intro {
    text-align: center;

    h2 {
      margin-top: rem(12px);
      margin-bottom: rem(48px);
    }
  }
}

.about-page-open-positions {
  background: $white;
  padding: rscale(100px) 0;
  border-bottom: 1px solid $body-bg;
}

.about-page-contact-form {
  padding: rscale(100px) 0;

  .title {
    max-width: rscale(840px);
    margin: 0 auto;
    margin-bottom: rem(48px);
  }

  form {
    max-width: rscale(840px);
    margin: 0 auto;
  }
}

.home-events-articles-section {
  padding: rscale(100px) 0 rscale(20px) 0;

  @include media-breakpoint-only(lg) {
    .blogposts {
      li {
        width: calc(50% - 1.5rem);

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-only(md) {
    .blogposts {
      li {
        width: calc(50% - 1.5rem);

        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}

.home-cta-section {
  background: $white;
  padding: rscale(100px) 0;
  border-bottom: 1px solid $body-bg;

  @include media-breakpoint-down(sm) {
    padding-bottom: rscale(72px);
  }
}
