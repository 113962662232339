@mixin icon($content: '') {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
      content: $content;
  }
}

@mixin line-clamp($lines: 2) {
  display: -webkit-box;
  display: -moz-box;
  overflow: hidden;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}