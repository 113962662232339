*, *::after, *::before {
    box-sizing: border-box;
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img, svg {
    vertical-align: middle;
}

strong, b {
    font-weight: 500;
}

blockquote {
    margin: 0 0 1rem;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

[class^="rwe-"] {
    a {
        color: inherit;
        text-decoration: none;
    }

    h1, h2, h3, h4, p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}