@import '../../../fonts/Icomoon/desktop/style.scss';

.rwe-footer {
    $block: &;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    background-color: $primary--charcoal-black;
    color: $slate--light-slate;

    &__container {
        width: 1600px;
        max-width: 100%;

        padding: 60px;

        @include rwe-breakpoint-down(tablet) {
            padding: 24px
        }
    }


    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    /**
     * Footer section with columns and sign-up input
     */
     &__links-and-sign-up-container {
        display: flex;
        flex-direction: row;

        @include rwe-breakpoint-down(tablet) {
            flex-direction: column;
            justify-content: center;
          }
    }

    /**
     * Footer Columns
     */
     &__columns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex: 1;

        @include rwe-breakpoint-down(tablet) {
            flex-direction: column;
            justify-content: center;
          }
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }

        @include rwe-breakpoint-down(tablet) {
            margin-right: 0;
            margin-bottom: 16px;
        }

        &--collapsed {
            @include rwe-breakpoint-down(tablet) {
                overflow: hidden;

                #{$block}__column-links-container {
                    max-height: 0px;
                }

                #{$block}__column-heading-icon {
                    @include icon($icon-add)
                }

            }
        }
    }

    &__column-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: $primary--white
    }

    &__column-heading-icon {
        font-size: 200%;

        color: $primary--white;

        @include rwe-breakpoint-down(tablet) {
            @include icon($icon-minus)
        }
    }

    &__column-links-container {
        margin-top: 8px;

        @include rwe-breakpoint-down(tablet) {
            max-height: 100vh;
            transition: all .5s;
        }
    }

    &__column-link {
        margin-top: 8px;

        a {
            color: $slate--light-slate;
        }
    }


    &__bottom-row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        margin-top: 37px;

        @include rwe-breakpoint-down(tablet) {
            flex-direction: column;

            text-align: center;
          }
    }

    &__social-links {
        display: flex;
        flex-direction: row;

        @include rwe-breakpoint-down(tablet) {
            justify-content: center
        }
    }

    &__copyright {
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
    }

    &__logo {
        margin-bottom: 9px;
    }

    &__newsletter-sign-up {
        @include rwe-breakpoint-up(mobile) {
            max-width: 300px;
        }
        @include rwe-breakpoint-down(tablet) {
            align-self: center;
        }

        &-disclaimer {
            margin-top: 8px;

            a {
                text-decoration: underline;
                text-decoration-color: currentColor;
            }
        }
    }
}
