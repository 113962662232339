﻿@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  1.00 Alerts
-----------------------------------------
*/

.alert {

  &-danger {
    color: $red;
    background: lighten($red, 33%);
    border-color: lighten($red, 33%);
  }

  &-warning {
    color: $gray-900;
    background: rgba($orange, 20%);
    border: none;
  }

  &-sm {
    padding: $alert-padding-y-sm $alert-padding-x-sm;
    font-size: $alert-font-size-sm;
  }

  &-inline {
    display: inline-block;
  }

  &-shadow {
    box-shadow: $box-shadow-sm;
  }

  &-outline {
    border: 1px dashed $hr-border-color;
    text-align: center;
    padding: rem(48px) rem(24px);
    margin-bottom: rem(24px);

    @include media-breakpoint-down(sm) {
      padding: rem(24px) rem(16px);
    }

    & > i {
      font-size: rem(72px);
      color: $gray-600;
    }

    & > h5 {
      margin: rem(16px) 0 rem(8px) 0;
    }

    & > p {
      color: $gray-700;
      font-size: rem(14px);
      line-height: 1.4;
      max-width: rem(400px);
      margin: 0 auto;
    }

    & > .btn-group {
      margin-top: rem(24px);
      justify-content: center;
    }
  }
}
