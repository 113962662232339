.rwe-404-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 80px 0;

    &__grey-box {
        padding: 40px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 48px;

        text-align: center;

        background-color: $slate--very-light-slate;

        border-radius: 10px;
    }
}