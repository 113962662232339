@import '../utils/mixins';
/*

-----------------------------------------
  1.03 Typography
-----------------------------------------

*/
$font-family-runway-east: 'Runway East';

$font-size-h1-runway-east: 120px;
$font-size-h2-runway-east: 72px;
$font-size-h3-runway-east: 56px;
$font-size-h4-runway-east: 40px;

// Small
$font-size-h1-runway-east-small: 72px;
$font-size-h2-runway-east-small: 64px;
$font-size-h3-runway-east-small: 48px;
$font-size-h4-runway-east-small: 36px;

// Alt
$font-size-h1-alt: 56px;
$font-size-h2-alt: 40px;
$font-size-h3-alt: 32px;
$font-size-h4-alt: 24px;

// Alt Small
$font-size-h1-alt-small: 42px;
$font-size-h2-alt-small: 34px;
$font-size-h3-alt-small: 26px;
$font-size-h4-alt-small: 20px;

$font-size-p1: 16px;
$font-size-p2: 16px;
$font-size-p3: 14px;

.rwe-h1 {
  font-family: $font-family-runway-east;
  font-size: $font-size-h1-runway-east;
  font-weight: bold;
  line-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h1-runway-east-small;
  }

  &--article {
    font-size: 72px;

    @include rwe-breakpoint-down(mobile) {
      font-size: 56px;
    }
  }
}

.rwe-h1-alt {
  font-family: $font-family-sans-serif;
  font-size: $font-size-h1-alt;
  font-weight: 500;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h1-alt-small
  }
}

.rwe-h2 {
  font-family: $font-family-runway-east;
  font-size: $font-size-h2-runway-east;
  font-weight: bold;
  line-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h2-runway-east-small;
  }

  &--article {
    font-size: 56px;

    @include rwe-breakpoint-down(mobile) {
      font-size: 48px;
    }
  }
}

.rwe-h2-alt {
  font-family: $font-family-sans-serif;
  font-size: $font-size-h2-alt;
  font-weight: 500;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h2-alt-small;
  }
}

.rwe-h3 {
  font-family: $font-family-runway-east;
  font-size: $font-size-h3-runway-east;
  font-weight: bold;
  line-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h3-runway-east-small;
  }

  &--article {
    font-size: 48px;

    @include rwe-breakpoint-down(mobile) {
      font-size: 36px;
    }
  }
}

.rwe-h3-alt {
  font-family: $font-family-sans-serif;
  font-size: $font-size-h3-alt;
  font-weight: 500;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h3-alt-small;
  }
}

.rwe-h4 {
  font-family: $font-family-runway-east;
  font-size: $font-size-h4-runway-east;
  font-weight: bold;
  line-height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h4-runway-east-small;
  }
}

.rwe-h4-alt {
  font-family: $font-family-sans-serif;
  font-size: $font-size-h4-alt;
  font-weight: 500;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include rwe-breakpoint-down(mobile) {
    font-size: $font-size-h4-alt-small;
  }
}

.rwe-p1, body {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 140%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--medium {
    font-weight: 500;
    line-height: 24px;
  }

  &--article {
    line-height: 180%;
      margin: 16px 0;
  }
}

.rwe-p2 {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--medium {
    font-weight: 500;
    line-height: 140%;
  }
}

.rwe-p3 {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 125%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--medium {
    font-weight: 500;
    line-height: 140%;
  }
}

.rwe-caption {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
