﻿/*
-----------------------------------------
  1.01 Fonts
-----------------------------------------

100 - Thin
200 - Extra Light, Ultra Light
300 - Light
400 - Normal, Book, Regular
500 - Medium
600 - Semi Bold, Demi Bold
700 - Bold
800 - Extra Bold, Ultra Bold
900 - Black, Heavy

*/

$fontpath: "../../fonts/";

@font-face {
  font-family: 'CircularXX';
  src: url('#{$fontpath}CircularXX/CircularXX-Book.woff2') format('woff2');
  src: url('#{$fontpath}CircularXX/CircularXX-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CircularXX';
  src: url('#{$fontpath}CircularXX/CircularXX-Medium.woff2') format('woff2');
  src: url('#{$fontpath}CircularXX/CircularXX-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CircularXX';
  src: url('#{$fontpath}CircularXX/CircularXX-Bold.woff2') format('woff2');
  src: url('#{$fontpath}CircularXX/CircularXX-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}