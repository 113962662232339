.rwe-article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $primary--white;

    &__content {
        width: 780px;
        max-width: 100%;

        padding-bottom: 120px;

        .rwe-content-container {

            > h1, h2, h3, h4, h5, h6 {
                margin-top: 32px;
                margin-bottom: 16px;
            }
            
            li {
                .rwe-p1--article {
                    margin: 0;
                }
            }
            
            .rwe-structured-text {

                &__image-with-caption {
                    margin-top: 48px;
                    margin-bottom: 32px;        
                }

                &__newsletter-signup {
                    margin-top: 48px;
                    margin-bottom: 32px;
                    
                    @include rwe-breakpoint-down(mobile) {
                        margin-top: 48px;
                        margin-bottom: 32px;
                    }
                }
                
                &__quote {
                    margin-top: 48px;
                    margin-bottom: 32px;
                    
                    @include rwe-breakpoint-down(mobile) {
                        margin-top: 48px;
                        margin-bottom: 32px;
                    }
                }
            }
        }
    }
}