.rwe-input {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 50px;

    border-width: 1px;
    border-style: solid;
    border-color: $slate--dark-slate;
    border-radius: 5px;

    &--active {
        border-color: $primary--teal;
    }

    &--error {
        border-color: $secondary--red;
    }

    &-with-message {
        display: flex;
        flex-direction: column;

        &__message {
            margin-top: 8px;

            color: $primary--white;
        }
    }

    &__input {
        flex: 1;
        height: 100%;
        
        > input {
            width: 100%;
            height: 100%;

            padding: 0 16px;

            background-color: transparent;

            border: 0;

            &:focus-visible {
                outline: 0;
            }
        }
    }

    &__embedded-button-on-right {
        position: relative;
        top: -4px;
        left: 1px;

        > .rwe-button {
            &.rwe-button--primary, &.rwe-button--secondary {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}