.rwe-workspace-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        background-color: $primary--white;

        padding-top: 24px;

        &-text > h1 {
            font-size: 72px;
            line-height: 110%;
            color: $primary--charcoal-black;

            margin-left: -3px;

            @include rwe-breakpoint-down(mobile) {
                font-size: 48px;

                margin-left: -3px;
            }
        }
    }

    &__site-name-address-and-phone-number {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 24px 0;

        @include rwe-breakpoint-down(mobile) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__site-name {
        color: $primary--charcoal-black;

        font-weight: 600;

        @include rwe-breakpoint-down(mobile) {
            margin-bottom: 16px;
        }
    }

    &__address {
        color: $slate--dark-slate;

        @include rwe-breakpoint-down(mobile) {
            margin-bottom: 16px;
        }

        &-dot {
            width: 6px;
            height: 6px;
            border-radius: 100%;

            margin: 0 16px;

            background-color: $slate--mid-slate;

            @include rwe-breakpoint-down(mobile) {
                display: none;
            }
        }
    }

    &__phone-number {
        color: $primary--teal;
        
        a {
            text-decoration-color: currentColor;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__reusable-components {
        width: 100%;
    }
}