/*

-----------------------------------------------------------------------
  1. Components
-----------------------------------------------------------------------

*/

/*

-----------------------------------------
  1.01 Fonts
-----------------------------------------

*/

@mixin font-face($family, $file-base, $weight: normal, $style: normal, $format: 'woff2') {
  @font-face {
    font-family: $family;
    src: url('../../../fonts/' + $file-base + '.' + $format) format($format);
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Regular-Trial', 400, normal);
@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Regular-Oblique-Trial', 400, oblique);
@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Medium-Trial', 500, normal);
@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Medium-Oblique-Trial', 500, oblique);
@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Bold-Trial', 700, normal);
@include font-face('GT-Walsheim', 'runway-east/GT-Walsheim-Bold-Oblique-Trial', 700, oblique);

@include font-face('Runway East', 'runway-east/RunwayEastSans-Bold', 400, normal, 'woff');
@include font-face('Runway East', 'runway-east/RunwayEastSans-Bold', 500, normal, 'woff');
@include font-face('Runway East', 'runway-east/RunwayEastSans-Bold', 700, normal, 'woff');