.rwe-solution-type-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        background-color: $primary--white;

        padding-top: 24px;

        &-text > h1 {
            color: #000;

            margin-left: -3px;

            @include rwe-breakpoint-down(tablet) {
                font-size: 72px;
                margin-left: -2px;
            }
        }
    }

    &__reusable-components {
        width: 100%;
    }
}