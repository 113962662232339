
.rwe-cta-section {

    .rwe-section {
        @include rwe-breakpoint-down(mobile) {
            padding: 0;
        }
    }

    .rwe-content-container {
        @include rwe-breakpoint-down(mobile) {
            padding: 0;
        }
    }
}

.rwe-image-with-overlapping-content {
    position: relative;
    display: inline-flex;

    width: 9 / 12 * 100%;

    @include rwe-breakpoint-down(mobile) {
        display: flex;
        flex-direction: column;

        width: 100%;
    }

    &__image {
        position: relative;
        left: -72px;
        width: 100%;
        min-height: 350px;
        height: 350px;
        overflow: hidden;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @include rwe-breakpoint-down(mobile) {
            position: static;
            left: 0;
            width: unset;
            height: 250px;

            > img {
                min-width: 100%;
            }
        }
    }

    &__content {
        position: relative;

        @include rwe-breakpoint-down(tablet) {
            // This will override the margin-right value set as an inline style for when the <FloatingCta /> is present. Margin is not required here because the <FloatingCta /> is fix-positioned at the bottom of the page
            margin-right: 0!important;
        }

        @include rwe-breakpoint-up(tablet) {
            right: -200px;
            margin-top: 175px;
            margin-left: -400px;
        }
    }
}
