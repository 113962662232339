.rwe-pill {
    display: flex;
    flex-direction: row;

    padding: 8px 16px;

    color: $primary--charcoal-black;
    background-color: $primary--white;

    border-width: 1px;
    border-style: solid;
    border-color: $primary--charcoal-black;
    border-radius: 24px;
    
    transition: all .2s;

    user-select: none;

    white-space: pre;
}

.rwe-pill-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include rwe-breakpoint-down(mobile) {
        flex-wrap: nowrap;

        width: 100%;

        overflow: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
}