.rwe-google-map {
    height: 100%;
}

.rwe-map-section {
    width: 100%;
    height: 600px;

    @include rwe-breakpoint-only(desktop) {
        height: 500px;
    }
    @include rwe-breakpoint-down(tablet) {
        height: 400px;
    }
}

.rwe-map-popup-content {
    width: 100%;
    height: 100%;

    background-color: #FFF;
    color: #000;

    &__heading {
        @extend .rwe-p3, .rwe-p3--medium;
        margin-bottom: 10px;
    }

    &__address-lines {
        @extend .rwe-p3;
        margin-bottom: 10px;
    }

    &__link {
        @extend .rwe-p3;
        
        > a {
            color: $primary--teal;
            text-decoration-color: currentColor;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}