$space--desktop: 54px;
$space--mobile: 16px;
$vertical-space--mobile: 48px;



.rwe-section {
    $block: &;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $primary--white;

    padding: $space--desktop 0;

    @include rwe-breakpoint-down(mobile) {
        padding: $vertical-space--mobile 0;
    }

    &--without-line {
        padding-top: 24px;
    }

    &--no-padding {
        padding: 0;

        @include rwe-breakpoint-down(mobile) {
            padding: 0;
        }
    }

    &--image-on {
        &-left, &-right {
            #{$block}__content-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @include rwe-breakpoint-down(tablet) {
                    flex-direction: column;
                    justify-content: center;
                }
            }

            #{$block}__content {
                display: flex;
                flex-direction: column;
                justify-content: center;

                flex: 1;
                // max-width: 600px;
            }

            #{$block}__line {
                &:before {
                    @include rwe-breakpoint-down(tablet) {
                        display: none;
                    }
                }
            }
        }

        &-left {

            #{$block}__image {
                position: relative;
                left: -72px;

                flex: 1;

                @include rwe-breakpoint-only(tablet) {
                    position: static;
                    left: 0;

                    margin: -72px -72px 48px -72px;
                }

                @include rwe-breakpoint-down(mobile) {
                    position: static;
                    left: 0;

                    margin: -48px -16px 24px -16px;
                }
            }
        }

        &-right {

            #{$block}__image {
                order: 1;

                position: relative;
                right: -72px;

                flex: 1;

                @include rwe-breakpoint-down(tablet) {
                    order: unset;
                    position: static;
                    right: 0;
                }

                @include rwe-breakpoint-only(tablet) {
                    margin: -72px -72px 48px -72px;
                }

                @include rwe-breakpoint-down(mobile) {
                    margin: -48px -16px 24px -16px;
                }
            }
        }
    }

    &--copy-on-left {

        #{$block}__content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include rwe-breakpoint-down(tablet) {
                flex-direction: column;
                justify-content: center;
            }
        }

        #{$block}__copy-and-cta {
            flex-direction: column;
            flex-basis: (4 / 12) * 100%;

            @include rwe-breakpoint-down(tablet) {
                margin-bottom: 32px;
            }
        }

        #{$block}__content {
            flex-basis: (7 / 12) * 100%;
        }

        #{$block}__cta {
            margin-top: 16px;

            @include rwe-breakpoint-down(tablet) {
                margin-top: 8px;
            }
        }
    }

    &--copy-above {

        #{$block}__copy-and-cta {
            flex-direction: row;
            justify-content: space-between;

            @include rwe-breakpoint-down(tablet) {
                flex-direction: column;
            }
        }

        #{$block}__cta {
            margin-top: 16px;
        }

        .rwe-content-container {
            flex-direction: column;
        }
    }

    &__copy-and-cta {
        display: flex;
        margin-bottom: 12px;
    }

    &__copy {
        max-width: 800px;
    }

    &__image {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;

        height: 450px;
        max-height: 450px;

        > img {
            max-height: 100%;
        }

        @include rwe-breakpoint-down(tablet) {
            > img {
                min-width: 100%;
                max-height: unset;
            }
        }

        @include rwe-breakpoint-down(mobile) {
            height: 300px;
            max-height: 300px;
        }
    }

    &__content-container {

        @include rwe-breakpoint-down(tablet) {
            // This will override the margin-right value set as an inline style for when the <FloatingCta /> is present. Margin is not required here because the <FloatingCta /> is fix-positioned at the bottom of the page
            margin-right: 0!important;
        }
    }

    &__line {
        &:before {
            content: '';

            position: absolute;
            top: -$space--desktop;
            left: $space--desktop;
            width: 61px;
            height: 3px;
            background-color: #000;

            @include rwe-breakpoint-down(desktop) {
                left: $space--desktop;
            }

            @include rwe-breakpoint-down(mobile) {
                top: -$vertical-space--mobile;
                left: $space--mobile;
            }
        }
    }
}

.rwe-gallery-carousel-section {
    width: 100%;

    padding-bottom: 48px;

    .rwe-content-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @include rwe-breakpoint-down(mobile) {
            padding: 0;
        }
    }

    .rwe-gallery-carousel {
        width: 9 / 12 * 100%;

        @include rwe-breakpoint-down(tablet) {
            width: 100%;
        }
    }
}
