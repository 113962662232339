.rwe-bookings-search-page {
    $card-border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 72px;

    .card {
        border-radius: $card-border-radius;
        box-shadow: 0px 7px 20px -6px rgba(68, 66, 95, 0.2);

        transition: box-shadow .5s;

        &:hover {
            box-shadow: 0px 7px 30px -6px rgba(68, 66, 95, 0.4);
        }
    }

    .card-bookings-available {
        &__image {
            border-top-left-radius: $card-border-radius;
            border-bottom-left-radius: $card-border-radius;

            @include media-breakpoint-down(sm) {
                border-radius: $card-border-radius;
            }
        }
    }

    &__map-btn-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 32px;
    }
}
