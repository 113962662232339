.rwe-newsletter-signup-ebox {
    &__text {
        margin-bottom: 24px;
    }

    &__under-input-text {
        margin-top: 8px;
    }

    .rwe-cta-box__content {
        padding-left: 48px;
    }
}