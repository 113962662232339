.booking-header {
  position: relative;
  top: rem(-40px);
  padding-top: rem(20px);
  background-color: #F5F5F5;
}

body.li, .content--dashboard, .content--custom {
  .booking-header {
    background-color: #F5F5F5;
  }
}

.rwe-page-content {
  .booking-header {
    background-color: white;
  }
}
