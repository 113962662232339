.rwe-multi-column-section {
  &__card-theme {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px -6px rgba(96, 92, 132, 0.2);
    border-radius: 5px;
  }

    .rwe-flex-grid {
        margin-top: 16px;
        margin-right: -48px;

        @include rwe-breakpoint-down(mobile) {
            margin-right: 0;
        }
        &__item {
            padding-right: 48px;

            @include rwe-breakpoint-down(mobile) {
                padding-right: 0;
            }
        }
    }
}
