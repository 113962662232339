.rwe-image-with-caption {
    > img {
        width: 100%;
    }

    &__caption {
        margin-top: 8px;

        color: $slate--dark-slate;

        text-align: center;
    }
}