.rwe-article-category-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #FFF;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        background-color: $primary--white;

        padding-top: 24px;

        h1 {
            color: #000;

            margin-left: -3px;

            @include rwe-breakpoint-down(tablet) {
                font-size: 72px;
                margin-left: -2px;
            }
        }
    }

    &__categories {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;

        margin: 16px 0;


        @include rwe-breakpoint-down(mobile) {
            .rwe-content-container {
                padding: 0;

                .rwe-pill-list {
                    padding: 0 16px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;

        padding-bottom: 120px;
    }

    &__empty-content-text {
        padding-top: 80px;

        text-align: center;

        color: $primary--charcoal-black;
    }
    
    &__loading-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        width: 100%;

        margin-top: 72px;
    }

    .rwe-card--article {
        height: 388px;
    }
}