﻿@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  3.00 Blog
-----------------------------------------
*/

.blog-content {
  padding-bottom: rem(32px);

  @include media-breakpoint-only(md) {
    padding-bottom: rem(24px);
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: rem(16px);
  }
}

.blog-post-meta-data {
  .category-date {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    color: $gray-700;

    .category {
      margin-right: rem(8px);

      span {
        &:not(:last-child) {
          padding-right: rem(4px);

          &:after {
            content: ", ";
          }
        }

        a {
          color: $gray-700;
          font-size: rem(14px);
        }
      }

      &:after {
        content: "•";
        margin-left: rem(8px);
      }
    }
  }
}

.blog-post-details {

  &__image {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding-top: 54%;
  }

  &__content {
    padding: rem(48px) rem(106px);

    @include media-breakpoint-only(md) {
      padding: rem(24px);
    }

    @include media-breakpoint-down(sm) {
      padding: rem(16px);
    }

    img {
      max-width: 100%;
    }

    .fr-rounded {
      border-radius: $border-radius;
    }

    blockquote {
      border-left: rem(4px) solid $bg-color;
      padding-left: rem(8px);
      line-height: 1.33;
      margin-bottom: 0;
    }

    table {
      tr {
        td {
          padding: rem(8px);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.blog-post-author {
  margin: 0 rem(24px);
  padding: rem(32px) rem(82px);
  border-top: 1px solid $gray-200;

  @include media-breakpoint-only(md) {
    padding: rem(24px) 0 0 0;
  }

  @include media-breakpoint-down(sm) {
    padding: rem(16px) 0 0 0;
    margin: 0 rem(16px);
  }
}

.blog-post-comments-form {
  .nx-mentions-input-wrapper {
    padding: 0 rem(12px);

    .form-control {
      border: none;
      font-size: rem(16px);

      .form-control__control {
        font-family: $font-family-sans-serif !important;

        .form-control__highlighter, .form-control__input {
          padding: rem(5px) 0 rem(4px) 0 !important;
          min-height: rem(32px) !important;
          font-size: rem(16px);
          @include placeholder(1, $gray-600, 400);
        }
      }
    }
  }
}
