.rwe-logo {
  padding-top: 100%;
  position: relative;
  
  .rwe-image {
    position: absolute;
    top: 0;

    img {
      object-fit: contain;
    }
  }
}
