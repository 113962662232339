﻿@import "../utils/mixins";
@import "../variables";
@import "./ui/assets/fonts/Icomoon/desktop/style";

/*
-----------------------------------------
  1.00 Modals
-----------------------------------------
*/

.modal {
  overflow: auto;

  .modal-dialog {

    .modal-content {

      & > .close {
        position: absolute;
        top: rem(-48px);
        right: 0;
        margin: 0;
        padding: 0;
        float: none;
        text-shadow: none;
        font-size: rem(40px);
        line-height: 1;
        color: $white;
        opacity: 1;
        z-index: $zindex-modal + 1;
        outline: 0;

        &:hover, &:active, &:focus {
          outline: 0;
        }
      }

      .modal-header {
        padding: $modal-inner-padding-x $modal-inner-padding-y 0 $modal-inner-padding-y;
      }

      .modal-body {
        .container-fluid {
        }
      }

      .modal-footer {
        padding: 0 $modal-inner-padding-y $modal-inner-padding-x $modal-inner-padding-y;
      }
    }

    &.modal-dialog-scrollable {
      .modal-content {
        .modal-body {
          @include scrollbar($white,14px,4px,rgba($gray-900, 0.15));
        }
      }
    }
  }
}

.modal-booking-details {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: rem(24px) rem(12px);

        @include media-breakpoint-down(sm) {
          padding: rem(24px) rem(6px);
        }

        .booking-resource-info {
          &--description {
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .teams-accordion, .prev-guests-accordion {
    .icon-attendee-add {
      color: $gray-600;

      &:hover {
        color: $gray-900;
      }
    }

    .add-team {
      color: $gray-600;

      &:hover {
        background-color: $gray-200;
        border-radius: 8px;
      }
    }

    .attendee-added {
      background-color: $green-200;
      color: $green;
      border-radius: 8px;
      @extend .icon-check;

      &:hover {
        background-color: $red-200;
        color: $red;
        @extend .icon-close;
      }
    }
  }
  
  .new-attendee-accordion {
    .btn.disabled {
      cursor: initial;
    }
  }
}

.modal-community-start {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding-left: rem(60px);
        padding-right: rem(60px);

        @include media-breakpoint-down(sm) {
          padding-left: rem(16px);
          padding-right: rem(16px);
        }
      }

      .modal-body {
        padding: rem(20px) rem(60px);

        @include media-breakpoint-down(sm) {
          padding: rem(16px);
        }

        .mentions-input {
          .form-control__highlighter, .form-control__input {
            min-height: rem(100px) !important;

            @include media-breakpoint-down(sm) {
              min-height: rem(140px) !important;
            }
          }
        }
      }
    }
  }
}

.modal-tickets {
  .card-event {
    height: auto;
    min-height: 0;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: rem(20px);

    .card-event__image {
      width: rem(144px);

      @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        height: rem(240px);
      }
    }

    .card-event__main {
      padding-left: rem(164px);
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      min-height: rem(144px);

      @include media-breakpoint-down(sm) {
        padding: rem(16px) 0;
        min-height: 0;
      }
    }

    .card-event__footer {
      margin-top: rem(12px);
    }

    .card-event__title {
      font-size: rem(24px);
      line-height: 1.2;
    }

    .card-event__calendar {
      display: none;
    }

    .card-event__attendees {
      display: block;
    }

    .card-event__price {
      display: none;
    }
  }

  .modal-body {
    @include media-breakpoint-down(sm) {
      padding: rem(16px);
    }
  }
}

.modal-graduation {
  .modal-dialog {
    .modal-content {
      padding: rem(60px) 0;

      .icon {
        font-size: rem(80px);
        color: $green;
      }

      .modal-header {
        font-size: rem(40px);
        font-weight: 500;
        padding: 0 rem(170px);
      }

      .modal-body {
        padding: 0 rem(90px);
      }

      .modal-footer {
        padding: 0;
      }
    }
  }
}

.modal-popup-message, .modal-yes-no-message {
  z-index: $zindex-popover;
  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
}

@include media-breakpoint-up(md) {
  .modal-xl {
    max-width: $modal-xl !important;
    width: 100% !important;
    padding: 0 rem(12px);
  }

  .modal-lg {
    max-width: $modal-lg !important;
    width: 100% !important;
    padding: 0 rem(12px);
  }
}

@include media-breakpoint-down(sm) {
  body.modal-open {
    position: fixed;
    overflow: hidden;
  }

  .modal-xl {
    max-width: $modal-xl !important;
    width: 100% !important;
    padding: 0 rem(4px);
  }

  .modal-lg {
    max-width: $modal-lg !important;
    width: 100% !important;
    padding: 0 rem(4px);
  }
}
