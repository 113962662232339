﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  2.00 Content
-----------------------------------------
*/

body {
  &.li {
    overflow: hidden;

    .content {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: auto;

      @include media-breakpoint-up(xl) {
        @include scrollbar($body-bg);

        padding-bottom: 120px;
      }
    }
  }
}

.content {
  &.content--custom {
  }

  &.content--home {
  }

  &.content--about {
  }

  &.content--dashboard {
  }

  &.content--signup {
  }

  &.content--fullpage {
    margin-top: $site-client-header-height !important;
    height: calc(100vh - #{$site-client-header-height}) !important;
  }

  &.capacity {
  }

  &.board {
  }

  &.faq {
  }
}
