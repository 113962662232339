.rwe-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    border-radius: 100%;

    background-color: $slate--light-slate;
    color: black;
}