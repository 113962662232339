﻿.rwe-dropdown-menu {
  position: absolute;
  top: 100%;
  display: inline-flex;
  flex-direction: column;
  background-color: $primary--white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  
  white-space: nowrap;
  
  padding: 12px 0;
  
  &--right-aligned {
    right: 0;
  }
  
  &--find-a-space {
    background-color: $primary--teal;
    border-radius: 0 8px 8px 8px;
    border: none;
    
    padding: 0;
    
    box-shadow: 0px 3px 0px #17A98E;
    
    @include rwe-breakpoint-down(tablet) {
      border-radius: 8px;
    }
  }

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 4px 16px;

    color: $primary--charcoal-black;
    white-space: pre;
    user-select: none;

    &:hover {
      background-color: $slate--very-light-slate;
    }

    &-left-accessory-container {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }
  }

  &-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 16px 8px;

    color: $slate--dark-slate;
    white-space: pre;
    user-select: none;


    &:last-child {
      padding-bottom: 0;
    }
    
    &-left-accessory-container {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }
  }

  &-item-group {
    margin-top: 12px;
  }
}