@import './typography';

.rwe-animated-header {
  position: relative;
  line-height: 1;
  margin-bottom: 0;
  display: block;
  overflow-y: hidden;

  &__spacer {
    height: 1em * 0.9375;
    margin-right: -7px;
    width: 40px;
    display: inline-block;
    background-color: currentColor;
    &.-animated {
      animation: easeOutElastic 3s infinite both;
    }
  }

  &__segment {
    height: 100%;
    display: inline-block;

    &:first-child {
      margin-right: 0.03em;
    }
  }
}

// Handy functions:
// function easeOutElastic(x) {
//   const c4 = (2 * Math.PI) / 3;

//   return x === 0
//     ? 0
//     : x === 1
//     ? 1
//     : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
// }
// function calc(perc) {
//   const stepPerc = easeOutElastic(perc);
//   const high = 140;
//   const low = 40;
//   const diff = high - low;
//   return high - (stepPerc * diff);
// }

@keyframes easeOutElastic {
  0% {
    width: 40px;
  }

	50% {
		width: 140px;
	}

	58% {
		width: 7.733162573205419px;
	}

	64% {
		width: 53.11735205511336px;
	}

	72% {
    width: 35.36689211586054px;
	}

	79% {
    width: 41.63805100142194px;
	}

	83% {
    width: 39.42028946316364px;
	}

	85% {
    width: 40.20495862586115px;
	}

	100% {
    width: 40px;
	}

}

@keyframes scale-up-hor-left {
  0% {
    width: 40px;
  }

  75% {
    width: 120px;
  }

  95% {
    width: 35px;
  }

  98% {
    width: 35px;
  }

  100% {
    width: 40px;
  }
}
