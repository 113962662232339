$icomoon-font-family: "nexudus-icons-desktop" !default;
$icomoon-font-path: "fonts" !default;

$icon-add: "\e900";
$icon-whiteboard-2: "\e901";
$icon-graduation: "\e902";
$icon-privacy-shield: "\e908";
$icon-added-services: "\e909";
$icon-add-photo: "\e90a";
$icon-add-small: "\e90b";
$icon-alert: "\e90c";
$icon-android: "\e90d";
$icon-android-square: "\e90e";
$icon-apple: "\e90f";
$icon-apple-square: "\e910";
$icon-arrow-bend-down: "\e911";
$icon-arrow-right: "\e912";
$icon-arrow-right-small: "\e913";
$icon-ask-for-help: "\e914";
$icon-attendee-add: "\e915";
$icon-blogger: "\e916";
$icon-book-edit: "\e917";
$icon-bookings: "\e918";
$icon-bookmark-tabs: "\e919";
$icon-bookmark-tabs-unfollow: "\e91a";
$icon-business-spaces: "\e91b";
$icon-calendar-small: "\e91c";
$icon-calendar-view: "\e91d";
$icon-card-view: "\e91e";
$icon-cart: "\e91f";
$icon-cart-empty: "\e920";
$icon-catering: "\e921";
$icon-cctv: "\e922";
$icon-check: "\e923";
$icon-chevron-right: "\e924";
$icon-childcare: "\e925";
$icon-claim-discount: "\e926";
$icon-clap: "\e927";
$icon-climate: "\e928";
$icon-close: "\e929";
$icon-close-large: "\e92a";
$icon-close-sidebar: "\e92b";
$icon-code: "\e92c";
$icon-coffee-and-tea: "\e92d";
$icon-comment: "\e92e";
$icon-community: "\e92f";
$icon-conference-call: "\e930";
$icon-conference-phone: "\e931";
$icon-confimred: "\e932";
$icon-credit-card: "\e933";
$icon-dashboard: "\e934";
$icon-date-availability: "\e935";
$icon-delete: "\e936";
$icon-desks: "\e937";
$icon-disconnect: "\e938";
$icon-discount: "\e939";
$icon-document: "\e93a";
$icon-dollar: "\e93b";
$icon-download: "\e93c";
$icon-drinks: "\e93d";
$icon-dropbox-square: "\e93e";
$icon-dropdown: "\e93f";
$icon-dropdown-large: "\e940";
$icon-dropdown-tiny: "\e941";
$icon-edit: "\e942";
$icon-email: "\e943";
$icon-email-delivery: "\e944";
$icon-email-square: "\e945";
$icon-expand-sidebar: "\e946";
$icon-external-link: "\e947";
$icon-featured-article: "\e948";
$icon-filter: "\e949";
$icon-flickr: "\e94a";
$icon-floorplan-view: "\e94b";
$icon-github: "\e94c";
$icon-go-back: "\e94d";
$icon-google-square: "\e94e";
$icon-heating: "\e94f";
$icon-home-square: "\e950";
$icon-inbox: "\e951";
$icon-instant-delivery: "\e952";
$icon-intercom: "\e953";
$icon-invoice: "\e954";
$icon-large-display: "\e955";
$icon-likes: "\e956";
$icon-link: "\e957";
$icon-location: "\e958";
$icon-lock: "\e959";
$icon-log-out: "\e95a";
$icon-map-square: "\e95b";
$icon-marker-pin: "\e95c";
$icon-marker-pin-circle: "\e95d";
$icon-medium: "\e95e";
$icon-meeting-room: "\e95f";
$icon-membership: "\e960";
$icon-menu: "\e961";
$icon-minus: "\e962";
$icon-money: "\e963";
$icon-more: "\e964";
$icon-my-bookings-end-date: "\e965";
$icon-my-bookings-start-date: "\e966";
$icon-my-discussion-board: "\e967";
$icon-my-space: "\e968";
$icon-natural-light: "\e969";
$icon-navigation: "\e96a";
$icon-network: "\e96b";
$icon-next-date: "\e96c";
$icon-note: "\e96d";
$icon-notifications: "\e96e";
$icon-paypal: "\e96f";
$icon-pending: "\e970";
$icon-perk-benefits: "\e971";
$icon-phone: "\e972";
$icon-phone-square: "\e973";
$icon-pin-location-sqaure: "\e974";
$icon-pinterest: "\e975";
$icon-privacy-screen: "\e976";
$icon-private: "\e977";
$icon-products: "\e978";
$icon-projector: "\e979";
$icon-quiet-zone: "\e97a";
$icon-remove: "\e97b";
$icon-replies: "\e97c";
$icon-reply-received: "\e97d";
$icon-request-date: "\e97e";
$icon-request-sent: "\e97f";
$icon-safe-box: "\e980";
$icon-search: "\e981";
$icon-send: "\e982";
$icon-settings: "\e983";
$icon-simple-pay: "\e984";
$icon-skype: "\e985";
$icon-slack: "\e986";
$icon-social-facebook: "\e987";
$icon-social-instagram: "\e988";
$icon-social-linkedin: "\e989";
$icon-social-tumblr: "\e98a";
$icon-social-twitter: "\e98b";
$icon-soundproof: "\e98c";
$icon-standing-desk: "\e98d";
$icon-start-conversation: "\e98e";
$icon-starting-time: "\e98f";
$icon-support: "\e990";
$icon-tea-and-coffee: "\e991";
$icon-text-bold: "\e992";
$icon-text-bullet-list: "\e993";
$icon-text-check-list: "\e994";
$icon-text-italic-text: "\e995";
$icon-text-number-list: "\e996";
$icon-text-quote: "\e997";
$icon-text-strike: "\e998";
$icon-text-text-size: "\e999";
$icon-ticket: "\e99a";
$icon-time: "\e99b";
$icon-top-facilites: "\e99c";
$icon-tumblr: "\e99d";
$icon-unlike: "\e99e";
$icon-unpaid-invoices: "\e99f";
$icon-user: "\e9a0";
$icon-user-circle: "\e9a1";
$icon-video-call: "\e9a2";
$icon-vimeo: "\e9a3";
$icon-voice-recorder: "\e9a4";
$icon-website-url: "\e9a5";
$icon-whiteboard: "\e9a6";
$icon-wifi: "\e9a7";
$icon-wireless-charger: "\e9a8";
$icon-world-square: "\e9a9";
$icon-youtube-square: "\e9aa";
$icon-zoom: "\e9ab";

