﻿@import '../utils/mixins';
@import '../variables';
/*
-----------------------------------------
  1.00 FullCalendar
-----------------------------------------
*/

#fc-wrapper {
  &.loading {
    .fc-view-container {
      opacity: 0.3;
    }

    * {
      cursor: progress !important;
    }
  }

  .fc-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $input-btn-height;
    font-weight: $btn-font-weight;
    color: $gray-600;
    background-color: transparent;
    border: 1px solid transparent;
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $input-btn-font-size;
    line-height: $btn-line-height;
    border-radius: $btn-border-radius;
    outline: 0 !important;
    box-shadow: none;
    @include transition();

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: transparent;
      color: $gray-900;
      outline: 0 !important;
      box-shadow: none;
    }

    &.fc-button-active {
      background-color: $gray-900;
      border-color: $gray-900;
      color: $white;
    }

    &.fc-button-primary {
      background-color: $gray-100;
      border-color: $gray-100;
      color: $gray-600;

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-300;
        border-color: $gray-300;
        color: $gray-900;
      }

      &.fc-button-active {
        background-color: $gray-900;
        border-color: $gray-900;
        color: $white;
      }
    }

    &.fc-prev-button,
    &.fc-next-button {
      background-color: $white;
      border-color: $white;
      box-shadow: $box-shadow-sm;
      margin-left: rem(4px);
      padding: 0;
      width: $input-btn-height;
      text-align: center;
      justify-content: center;

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-300;
        border-color: $gray-300;
        color: $gray-900;
      }
    }

    &.fc-today-button {
      text-transform: capitalize;
    }
  }

  /*--*/

  .fc-toolbar {
    h2 {
      font-size: $h4-font-size;
      font-weight: 500;
      text-transform: capitalize;

      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }

    .fc-button-group {
      border-left: 2px solid $gray-300;
      padding-left: rem(8px);
      margin-left: rem(12px) !important;
    }

    &.fc-header-toolbar {
      margin-bottom: rem(24px);
    }

    &.fc-resources-toolbar {
      margin-bottom: rem(20px);
    }

    & > * > :not(:first-child) {
      margin-left: rem(4px);
    }
  }

  .fc-view-container {
    background: $white;

    .fc-view {
      z-index: 0 !important;
      table {
        z-index: 0 !important;
      }
      &.fc-timeline {
        .fc-cell-text {
          font-size: rem(14px);
        }
      }

      &.fc-timeGrid-view {
        .fc-widget-header {
        }

        .fc-axis {
          &.fc-time {
            font-size: rem(14px);
            font-weight: 500;
            color: $gray-600;
          }
        }
      }

      &.fc-dayGrid-view {
        .fc-widget-header {
        }

        .fc-widget-content {
          .fc-day-top {
            position: relative;

            .fc-day-number {
            }
          }
        }
      }
    }
  }

  /*--*/

  .fc-event {
    border: none;
    border-radius: $border-radius-sm;
    padding: rem(2px) rem(4px);
    margin: rem(2px) 0;
    cursor: pointer;
    font-size: rem(12px);
    font-weight: 500;

    &.fc-time-grid-event {
    }

    &.fc-day-grid-event {
      position: relative;
      background-color: $gray-100 !important;
      border-color: $gray-100 !important;
      color: $gray-900 !important;

      .fc-content {
        display: flex;
        flex-direction: column;
        padding-left: rem(8px);

        .higlight-bar {
          position: absolute;
          top: rem(0px);
          left: rem(0px);
          bottom: rem(0px);
          display: block;
          width: rem(4px);
          border-radius: rem(2px);
          background-color: $gray-700;
          order: 0;
        }

        .fc-time {
          display: block;
          font-weight: 400;
          color: $gray-700;
          order: 2;
        }

        .fc-title {
          display: block;
          font-weight: 500;
          color: $gray-900;
          order: 1;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $gray-200 !important;
        border-color: $gray-200 !important;
      }

      &.fc-time-grid-event {
        .fc-content {
          flex-direction: row;
          align-items: center;

          .fc-time {
            padding-left: rem(4px);
            padding-top: 1px;
          }
        }
      }

      &.basket-booking {
      }
    }
  }

  .fc-day-header {
    &.fc-today {
      color: $gray-900;
    }
  }

  .fc-day {
    &.fc-other-month {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKklEQVQYV2NkYGCQYmBgeMaABhihfAxJmARIHkUSWQJFEl0CLolNAiwJAKjUBSHIEo60AAAAAElFTkSuQmCC)
        repeat;
    }

    &.fc-today {
      background: rgba($blue, 2%);
    }
  }

  .fc-day-top {
    .fc-day-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(24px);
      height: rem(24px);
      border-radius: 50%;
      margin: rem(4px);
      font-size: rem(14px);
      font-weight: 500;
    }

    &.fc-today {
      .fc-day-number {
        background: $bg-color;
        color: $text-color;
      }
    }
  }

  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td {
    border-color: $gray-200;
  }

  .fc-scroller {
    @include scrollbar($white, 17px, 4px, rgba($gray-900, 0.33));
  }
}

.events-fullcalendar {
  .fc-event {
    &.fc-time-grid-event {
      background-color: $bg-color;
      color: $text-color;
      border-color: $gray-100;
    }
  }
}
