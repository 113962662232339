﻿@import '../utils/mixins';
@import '../variables';

/*
-----------------------------------------
  2.00 MainMenu
-----------------------------------------
*/

.site-mainmenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed + 1;

  &__shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba($gray-900, 0.5);
    opacity: 0;

    @include media-breakpoint-up(md) {
      @include transition(0.2s, all, ease, 0s);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: $site-mainmenu-contracted-width;
    background: $white;
    @include transition(0.2s, width, ease-in-out, 0s);
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: rem(20px) rem(24px) rem(12px) rem(24px);
    min-height: rem(100px);

    .brand {
      display: flex;
      font-size: rem(20px);
      font-weight: 700;
      text-decoration: none;
      color: $gray-900;
      line-height: 1;

      img {
        max-width: rem(188px);
        max-height: rem(48px);
      }
    }

    .toggle-desktop,
    .toggle-mobile {
      display: flex;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      font-size: rem(24px);
      line-height: 1;
      outline: none;
    }

    .toggle-mobile {
      display: none;
    }
  }

  &__nav {
    height: 100%;
    padding: rem(12px);
    @include scrollbar();

    button,
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: rem(40px);
      font-size: rem(14px);
      font-weight: 500;
      white-space: nowrap;
      border: none;
      border-radius: $border-radius;
      background: transparent;
      outline: none !important;
      box-shadow: none;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .dropdown {
      button {
        &.active {
          background: $gray-200 !important;

          i {
            color: $gray-900 !important;
          }
  
          span {
            color: $gray-900 !important;
          }
        }
      }
    }

    button {
      margin: rem(4px) 0;
      padding: 0 rem(32px) 0 rem(12px);

      i {
        font-size: rem(24px);
        color: $gray-900;
      }

      span {
        padding-left: rem(8px);
        color: $gray-600;
      }

      &:hover,
      &:active,
      &:focus {
        outline: none !important;
        background: $gray-200;

        i {
          color: $gray-900;
        }

        span {
          color: $gray-900;
        }

        &:after {
          border-bottom-color: $gray-200;
        }
      }

      &.active {
        background: $bg-color !important;
        color: $text-color !important;
        box-shadow: $box-shadow;

        i {
          color: $text-color !important;
        }

        span {
          color: $text-color !important;
        }
      }
    }

    a {
      margin: rem(2px) 0;
      padding: 0 rem(14px);
      text-decoration: none;
      color: $gray-600;

      &:hover,
      &:active,
      &:focus {
        background: $gray-200;
        color: $gray-900;
      }

      &.active {
        background: $bg-color !important;
        color: $text-color !important;
        box-shadow: $box-shadow;
      }
    }

    .dropdown {
      button {
        position: relative;

        &:before,
        &:after {
          display: block;
          content: '';
          position: absolute;
        }

        &:before {
          top: calc(50% - 3px);
          right: 12px;
          border-top: 5px solid $gray-600;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }

        &:after {
          top: calc(50% - 3px);
          right: 14px;
          border-top: 3px solid $white;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
        }

        &.active {
          &:before {
            border-top-color: $text-color;
          }

          &:after {
            border-top-color: $bg-color;
          }
        }
      }

      &.show {
        button {
          background: $gray-200;

          i {
            color: $gray-900;
          }

          span {
            color: $gray-900;
          }

          &:before {
            top: calc(50% - 8px);
            border-top: 5px solid transparent;
            border-bottom: 5px solid $gray-600;
          }

          &:after {
            top: calc(50% - 4px);
            border-top: 3px solid transparent;
            border-bottom: 3px solid $gray-200;
          }

          &.active {
            &:before {
              border-top: 5px solid transparent;
              border-bottom-color: $gray-600;
            }

            &:after {
              border-top: 3px solid transparent;
              border-bottom-color: $gray-200;
            }
          }
        }
      }
    }

    .locations-menu {
      margin-bottom: rem(32px);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__footer {
    display: none;
    align-items: center;
    justify-content: space-between;
    min-height: rem(68px);
    padding: rem(18px) rem(24px) rem(18px) rem(20px);

    .customer {
      display: flex;
      align-items: center;

      img {
        margin-right: rem(12px);
      }
    }

    .logout {
      display: flex;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      font-size: rem(24px);
      line-height: 1;
      color: $gray-600;
      outline: none;
      margin-left: rem(20px);
    }
  }

  &--expanded {
    .site-mainmenu__shadow {
      right: 0;
      width: 100vw;
      opacity: 1;
    }

    .site-mainmenu__content {
      width: $site-mainmenu-expanded-width;
    }

    .site-mainmenu__nav {
      overflow: auto;

      .dropdown.show {
        .dropdown-menu {
          position: relative !important;
          box-shadow: none;
          top: auto;
          display: block;
          float: none;
          min-width: 0;
          margin: rem(8px) 0 rem(8px) rem(24px);
          padding: 0;
          border-radius: 0;
          transform: none !important;
          padding-left: rem(4px);
          border-left: 2px solid $gray-300;
        }
      }
    }
  }

  &--contracted {
    .site-mainmenu__header {
      .brand {
        display: none;
      }
    }

    .site-mainmenu__nav {
      button {
        span {
          display: none;
        }

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }

        &.active {
          background: $bg-color !important;
          color: $text-color !important;
          box-shadow: $box-shadow;
  
          i {
            color: $text-color !important;
          }
  
          span {
            color: $text-color !important;
          }
        }
      }
    }
  }

  & ~ .site-header {
    @include media-breakpoint-up(md) {
      left: $site-mainmenu-contracted-width + rem(2px);
    }
  }

  & ~ .content {
    @include media-breakpoint-up(md) {
      margin-left: $site-mainmenu-contracted-width;
    }

    &.profile {
      overflow-y: scroll;
      @include media-breakpoint-down(xl) {
        @include scrollbar($body-bg);
      }
    }
  }

  &--docked {
    & ~ .content {
      @include media-breakpoint-up(md) {
        margin-left: $site-mainmenu-expanded-width + rem(20px);
      }
    }

    & ~ .site-header {
      @include media-breakpoint-up(md) {
        left: $site-mainmenu-expanded-width;

        .site-header__content {
          padding-left: 0;
        }

        .brand {
          display: none;
        }

        .locations-menu {
          margin-left: 0;

          .dropdown-menu {
            max-height: 17.5rem;
            overflow: auto;
          }

          &::before {
            display: none;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .site-mainmenu__shadow {
        right: 0;
        width: 0;
        opacity: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .site-mainmenu {
    .site-mainmenu__content {
      @include transition(0.2s, transform, ease-in-out, 0s);
    }
  }

  .site-mainmenu__content {
    width: $site-mainmenu-expanded-width;
  }

  .site-mainmenu__header {
    align-items: center;
    justify-content: flex-start;
    padding: rem(22px);
    min-height: rem(60px);

    .brand {
      margin-left: rem(18px);

      img {
        max-height: rem(24px);
        max-width: rem(196px);
      }
    }

    .toggle-desktop {
      display: none;
    }

    .toggle-mobile {
      display: flex;
    }
  }

  .site-mainmenu__nav {
    overflow: auto;
    padding-top: rem(18px);

    button {
      height: rem(48px);
      font-size: rem(16px);

      i {
        color: $gray-600;
      }
    }

    a {
      margin: 0;
      font-size: rem(16px);
    }

    .dropdown {
      button {
        &:before {
          border-width: 8px !important;
        }

        &:after {
          border-width: 6px !important;
        }
      }

      &.show {
        button {
          &:before {
            top: calc(50% - 13px);
          }

          &:after {
            top: calc(50% - 9px);
          }
        }

        .dropdown-menu {
          position: relative !important;
          box-shadow: none;
          top: auto;
          display: block;
          float: none;
          min-width: 0;
          margin: rem(8px) 0 rem(8px) rem(24px);
          padding: 0;
          border-radius: 0;
          transform: none !important;
          padding-left: rem(4px);
          border-left: 2px solid $gray-300;
        }
      }
    }
  }

  .site-mainmenu__footer {
    display: flex;
  }

  .site-mainmenu--expanded {
    transform: translateX(0);

    .site-mainmenu__content {
      transform: translateX(0);
    }
  }

  .site-mainmenu--contracted {
    transform: translateX(-1 * $site-mainmenu-expanded-width);

    .site-mainmenu__content {
      transform: translateX(-1 * $site-mainmenu-expanded-width);
    }
  }
}
