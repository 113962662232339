.rwe-carousel-window {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rwe-horizontal-scrollable-element {
    position: relative;

    min-width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    
    &__inner {
        display: flex;
        flex-direction: row;

        transition: transform .5s;
    }
}

.rwe-gallery-carousel {
    $block: &;

    $item-height: 510px;
    $item-height--tablet: 400px;
    $item-height--mobile: 280px;

    position: relative;

    .rwe-carousel-window {
        overflow-x: hidden;

        &__item {
            min-width: 100%;
            width: 100%;
            height: $item-height;

            @include rwe-breakpoint-only(tablet) {
                height: $item-height--tablet;
            }

            @include rwe-breakpoint-down(mobile) {
                height: $item-height--mobile;
            }

            padding-right: 0;

            background-color: $slate--very-light-slate;

            // Vimeo player
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    .rwe-carousel__indicators-container {
        position: relative;
        
        display: flex;
        flex-direction: row;

        margin-top: 16px;

        overflow: scroll;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        & > .rwe-carousel__indicator {
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        @include rwe-breakpoint-down(mobile) {
            display: none;
        }
    }

    &__thumbnail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 160px;
        height: 88px;

        background-color: $slate--very-light-slate;

        opacity: 0.5;

        transition-property: opacity;
        transition-duration: .5s;

        cursor: pointer;

        &--video {
            background-color: $primary--charcoal-black;

        }
        
        &-video-icon {
            position: absolute;
        }
        
        .rwe-carousel__indicator--active & {
            opacity: 1;
            cursor: default;
        }

        &:hover {
            opacity: 1;
        }

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__active-number-indicator {
        position: absolute;
        bottom: 16px;
        right: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        padding: 4px 8px;

        border: 1px solid $slate--light-slate;
        background-color: $primary--white;

        &-text {
            margin-left: 8px;

            color: #000;
        }
    }

    &__button {
        position: absolute;

        @include rwe-breakpoint-down(mobile) {
            display: none;
        }

        &--left {
            left: -16px;
        }

        &--right {
            right: -16px;
        }

        .rwe-button {
            padding: 30px 14px;
        }
    }
}

.rwe-testimonial-carousel {
    $block: &;

    $item-width: 357px;
    $item-height: 420px;
    $item-width--mobile: 230px;
    $item-height--mobile: 270px;

    position: relative;

    &__item {
        min-width: $item-width;
        max-width: $item-width;
        min-height: $item-height;
        max-height: $item-height;

        @include rwe-breakpoint-down(mobile) {
            min-width: $item-width--mobile;
            max-width: $item-width--mobile;
            min-height: $item-height--mobile;
            max-height: $item-height--mobile;
        }

        margin-right: 24px;

        filter: grayscale(100%);
        opacity: 0.2;

        transition-property: opacity;
        transition-duration: .5s;

        &--active {
            filter: unset;
            opacity: 1;
        }

    }
    .rwe-carousel-window {

        &--scrolling {
            #{$block}__testimonial-container {
                opacity: 0;
            }
        }

    }


    &__testimonial-container {
        position: relative;
        width: fit-content;

        margin-left: $item-width - 56px;
        margin-top: -$item-height + 57px;

        opacity: 1;

        transition-property: opacity;
        transition-duration: .5s;

        @include rwe-breakpoint-only(tablet) {
            margin-left: 72px;
            margin-top: -($item-height - 288px);
        }

        @include rwe-breakpoint-down(mobile) {
            margin-left: 0;
            margin-top: -31px;
        }

        .rwe-cta-box {
            height: 347px;

            &__content {
                padding-left: 48px;
            }

            &__testimonial-logo {
                margin-bottom: 24px;
            }
        
            &__testimonial-text {
                margin-bottom: 8px;
            }
        }
    }

    &__dot {
        width: 8px;
        height: 8px;
        
        border-radius: 100%;
        
        background-color: $slate--mid-slate;

        transform: scale(0.5);

        transition: all .5s;
        
        &--active {
            transform: scale(1);

            background-color: $primary--teal;
        }
    }

    &__button {
        position: absolute;

        @include rwe-breakpoint-down(mobile) {
            display: none;
        }

        &--left {
            left: -16px;
        }

        &--right {
            right: -16px;
        }
    }

    .rwe-carousel__indicators-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        margin-top: 40px;

        & > .rwe-carousel__indicator {
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        @include rwe-breakpoint-down(mobile) {
            margin-top: 32px;
        }
    }
}