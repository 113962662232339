@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mkkq22');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mkkq22#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mkkq22') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mkkq22') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mkkq22##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-whiteboard-2 {
  &:before {
    content: $icon-whiteboard-2; 
  }
}
.icon-graduation {
  &:before {
    content: $icon-graduation; 
  }
}
.icon-privacy-shield {
  &:before {
    content: $icon-privacy-shield; 
  }
}
.icon-added-services {
  &:before {
    content: $icon-added-services; 
  }
}
.icon-add-photo {
  &:before {
    content: $icon-add-photo; 
  }
}
.icon-add-small {
  &:before {
    content: $icon-add-small; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-android-square {
  &:before {
    content: $icon-android-square; 
  }
}
.icon-apple {
  &:before {
    content: $icon-apple; 
  }
}
.icon-apple-square {
  &:before {
    content: $icon-apple-square; 
  }
}
.icon-arrow-bend-down {
  &:before {
    content: $icon-arrow-bend-down; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-right-small {
  &:before {
    content: $icon-arrow-right-small; 
  }
}
.icon-ask-for-help {
  &:before {
    content: $icon-ask-for-help; 
  }
}
.icon-attendee-add {
  &:before {
    content: $icon-attendee-add; 
  }
}
.icon-blogger {
  &:before {
    content: $icon-blogger; 
  }
}
.icon-book-edit {
  &:before {
    content: $icon-book-edit; 
  }
}
.icon-bookings {
  &:before {
    content: $icon-bookings; 
  }
}
.icon-bookmark-tabs {
  &:before {
    content: $icon-bookmark-tabs; 
  }
}
.icon-bookmark-tabs-unfollow {
  &:before {
    content: $icon-bookmark-tabs-unfollow; 
  }
}
.icon-business-spaces {
  &:before {
    content: $icon-business-spaces; 
  }
}
.icon-calendar-small {
  &:before {
    content: $icon-calendar-small; 
  }
}
.icon-calendar-view {
  &:before {
    content: $icon-calendar-view; 
  }
}
.icon-card-view {
  &:before {
    content: $icon-card-view; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-cart-empty {
  &:before {
    content: $icon-cart-empty; 
  }
}
.icon-catering {
  &:before {
    content: $icon-catering; 
  }
}
.icon-cctv {
  &:before {
    content: $icon-cctv; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-childcare {
  &:before {
    content: $icon-childcare; 
  }
}
.icon-claim-discount {
  &:before {
    content: $icon-claim-discount; 
  }
}
.icon-clap {
  &:before {
    content: $icon-clap; 
  }
}
.icon-climate {
  &:before {
    content: $icon-climate; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-close-large {
  &:before {
    content: $icon-close-large; 
  }
}
.icon-close-sidebar {
  &:before {
    content: $icon-close-sidebar; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-coffee-and-tea {
  &:before {
    content: $icon-coffee-and-tea; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-conference-call {
  &:before {
    content: $icon-conference-call; 
  }
}
.icon-conference-phone {
  &:before {
    content: $icon-conference-phone; 
  }
}
.icon-confimred {
  &:before {
    content: $icon-confimred; 
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-date-availability {
  &:before {
    content: $icon-date-availability; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-desks {
  &:before {
    content: $icon-desks; 
  }
}
.icon-disconnect {
  &:before {
    content: $icon-disconnect; 
  }
}
.icon-discount {
  &:before {
    content: $icon-discount; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-dollar {
  &:before {
    content: $icon-dollar; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-drinks {
  &:before {
    content: $icon-drinks; 
  }
}
.icon-dropbox-square {
  &:before {
    content: $icon-dropbox-square; 
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown; 
  }
}
.icon-dropdown-large {
  &:before {
    content: $icon-dropdown-large; 
  }
}
.icon-dropdown-tiny {
  &:before {
    content: $icon-dropdown-tiny; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-email-delivery {
  &:before {
    content: $icon-email-delivery; 
  }
}
.icon-email-square {
  &:before {
    content: $icon-email-square; 
  }
}
.icon-expand-sidebar {
  &:before {
    content: $icon-expand-sidebar; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-featured-article {
  &:before {
    content: $icon-featured-article; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-flickr {
  &:before {
    content: $icon-flickr; 
  }
}
.icon-floorplan-view {
  &:before {
    content: $icon-floorplan-view; 
  }
}
.icon-github {
  &:before {
    content: $icon-github; 
  }
}
.icon-go-back {
  &:before {
    content: $icon-go-back; 
  }
}
.icon-google-square {
  &:before {
    content: $icon-google-square; 
  }
}
.icon-heating {
  &:before {
    content: $icon-heating; 
  }
}
.icon-home-square {
  &:before {
    content: $icon-home-square; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-instant-delivery {
  &:before {
    content: $icon-instant-delivery; 
  }
}
.icon-intercom {
  &:before {
    content: $icon-intercom; 
  }
}
.icon-invoice {
  &:before {
    content: $icon-invoice; 
  }
}
.icon-large-display {
  &:before {
    content: $icon-large-display; 
  }
}
.icon-likes {
  &:before {
    content: $icon-likes; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-map-square {
  &:before {
    content: $icon-map-square; 
  }
}
.icon-marker-pin {
  &:before {
    content: $icon-marker-pin; 
  }
}
.icon-marker-pin-circle {
  &:before {
    content: $icon-marker-pin-circle; 
  }
}
.icon-medium {
  &:before {
    content: $icon-medium; 
  }
}
.icon-meeting-room {
  &:before {
    content: $icon-meeting-room; 
  }
}
.icon-membership {
  &:before {
    content: $icon-membership; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-money {
  &:before {
    content: $icon-money; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-my-bookings-end-date {
  &:before {
    content: $icon-my-bookings-end-date; 
  }
}
.icon-my-bookings-start-date {
  &:before {
    content: $icon-my-bookings-start-date; 
  }
}
.icon-my-discussion-board {
  &:before {
    content: $icon-my-discussion-board; 
  }
}
.icon-my-space {
  &:before {
    content: $icon-my-space; 
  }
}
.icon-natural-light {
  &:before {
    content: $icon-natural-light; 
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation; 
  }
}
.icon-network {
  &:before {
    content: $icon-network; 
  }
}
.icon-next-date {
  &:before {
    content: $icon-next-date; 
  }
}
.icon-note {
  &:before {
    content: $icon-note; 
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications; 
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal; 
  }
}
.icon-pending {
  &:before {
    content: $icon-pending; 
  }
}
.icon-perk-benefits {
  &:before {
    content: $icon-perk-benefits; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-phone-square {
  &:before {
    content: $icon-phone-square; 
  }
}
.icon-pin-location-sqaure {
  &:before {
    content: $icon-pin-location-sqaure; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-privacy-screen {
  &:before {
    content: $icon-privacy-screen; 
  }
}
.icon-private {
  &:before {
    content: $icon-private; 
  }
}
.icon-products {
  &:before {
    content: $icon-products; 
  }
}
.icon-projector {
  &:before {
    content: $icon-projector; 
  }
}
.icon-quiet-zone {
  &:before {
    content: $icon-quiet-zone; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-replies {
  &:before {
    content: $icon-replies; 
  }
}
.icon-reply-received {
  &:before {
    content: $icon-reply-received; 
  }
}
.icon-request-date {
  &:before {
    content: $icon-request-date; 
  }
}
.icon-request-sent {
  &:before {
    content: $icon-request-sent; 
  }
}
.icon-safe-box {
  &:before {
    content: $icon-safe-box; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-simple-pay {
  &:before {
    content: $icon-simple-pay; 
  }
}
.icon-skype {
  &:before {
    content: $icon-skype; 
  }
}
.icon-slack {
  &:before {
    content: $icon-slack; 
  }
}
.icon-social-facebook {
  &:before {
    content: $icon-social-facebook; 
  }
}
.icon-social-instagram {
  &:before {
    content: $icon-social-instagram; 
  }
}
.icon-social-linkedin {
  &:before {
    content: $icon-social-linkedin; 
  }
}
.icon-social-tumblr {
  &:before {
    content: $icon-social-tumblr; 
  }
}
.icon-social-twitter {
  &:before {
    content: $icon-social-twitter; 
  }
}
.icon-soundproof {
  &:before {
    content: $icon-soundproof; 
  }
}
.icon-standing-desk {
  &:before {
    content: $icon-standing-desk; 
  }
}
.icon-start-conversation {
  &:before {
    content: $icon-start-conversation; 
  }
}
.icon-starting-time {
  &:before {
    content: $icon-starting-time; 
  }
}
.icon-support {
  &:before {
    content: $icon-support; 
  }
}
.icon-tea-and-coffee {
  &:before {
    content: $icon-tea-and-coffee; 
  }
}
.icon-text-bold {
  &:before {
    content: $icon-text-bold; 
  }
}
.icon-text-bullet-list {
  &:before {
    content: $icon-text-bullet-list; 
  }
}
.icon-text-check-list {
  &:before {
    content: $icon-text-check-list; 
  }
}
.icon-text-italic-text {
  &:before {
    content: $icon-text-italic-text; 
  }
}
.icon-text-number-list {
  &:before {
    content: $icon-text-number-list; 
  }
}
.icon-text-quote {
  &:before {
    content: $icon-text-quote; 
  }
}
.icon-text-strike {
  &:before {
    content: $icon-text-strike; 
  }
}
.icon-text-text-size {
  &:before {
    content: $icon-text-text-size; 
  }
}
.icon-ticket {
  &:before {
    content: $icon-ticket; 
  }
}
.icon-time {
  &:before {
    content: $icon-time; 
  }
}
.icon-top-facilites {
  &:before {
    content: $icon-top-facilites; 
  }
}
.icon-tumblr {
  &:before {
    content: $icon-tumblr; 
  }
}
.icon-unlike {
  &:before {
    content: $icon-unlike; 
  }
}
.icon-unpaid-invoices {
  &:before {
    content: $icon-unpaid-invoices; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user-circle {
  &:before {
    content: $icon-user-circle; 
  }
}
.icon-video-call {
  &:before {
    content: $icon-video-call; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-voice-recorder {
  &:before {
    content: $icon-voice-recorder; 
  }
}
.icon-website-url {
  &:before {
    content: $icon-website-url; 
  }
}
.icon-whiteboard {
  &:before {
    content: $icon-whiteboard; 
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi; 
  }
}
.icon-wireless-charger {
  &:before {
    content: $icon-wireless-charger; 
  }
}
.icon-world-square {
  &:before {
    content: $icon-world-square; 
  }
}
.icon-youtube-square {
  &:before {
    content: $icon-youtube-square; 
  }
}
.icon-zoom {
  &:before {
    content: $icon-zoom; 
  }
}

