@import './typography';

.rwe-header-center {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    max-height: 1200px;

    @include rwe-breakpoint-down(mobile) {
        min-height: 400px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        flex: 1;
        height: 100%;

        padding-top: 16px;
        padding-bottom: 72px;

        & > * {
            margin-top: 40px;

            &:first-child {
                margin-top: 0;
            }

            @include rwe-breakpoint-down(mobile) {
                margin-top: 24px;
            }
        }
    }

    &__heading {
        text-align: center;

        h1 {
            font-size: $font-size-h1-runway-east;

            @include rwe-breakpoint-only(tablet) {
                font-size: $font-size-h2-runway-east;
            }

            @include rwe-breakpoint-down(mobile) {
                font-size: $font-size-h1-runway-east-small;
            }
        }
    }

    &__text {
        text-align: center;

        max-width: 636px;

        color: $slate--dark-slate;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.rwe-header-with-masked-image {

    &__text {
        background-clip: text;
        -webkit-background-clip: text;
        background-position: center;

        color: transparent;

        font-family: 'Runway East';
        font-size: 16vw;
        font-weight: bold;
        line-height: 110%;
        letter-spacing: -1px;
        -webkit-font-smoothing: antialiased;

        @include rwe-breakpoint-up(large-desktop) {
            font-size: calc(1440px * 0.16);
        }
    }
}

.rwe-header-text-left-section {
    display: flex;
    flex-direction: row;
    justify-content: center;

    background-color: $primary--white;
}

.rwe-header-text-and-image-section {
    .rwe-section {

        @include rwe-breakpoint-up(desktop) {
            padding: 0;
        }

        &--image-on-left, &--image-on-right {
            .rwe-section__image {
                @include rwe-breakpoint-up(desktop) {
                    height: 600px;
                    max-height: 600px;
                }
            }
            .rwe-section__content {
                text-align: left;
                // max-width: 450px;

                @include rwe-breakpoint-up(desktop) {
                    padding: 72px 0;
                }

                @include rwe-breakpoint-only(tablet) {
                    align-self: center;

                    text-align: center;
                    // max-width: 415px;
                }
                @include rwe-breakpoint-only(mobile) {
                    align-self: center;

                    text-align: center;
                    max-width: unset;
                }
            }
        }
    }
}
