﻿@import "../utils/mixins";
@import "../variables";
/*
-----------------------------------------
  3.00 Bookings
-----------------------------------------
*/

.bookings-available-slots {
  display: flex;
  flex-wrap: nowrap;
  height: rem(40px);
  padding: rem(4px) 0;

  .slot {
    position: relative;
    flex-grow: 1;
    min-width: rem(4px);
    margin-left: rem(1px);
    margin-top: rem(6px);
    margin-bottom: rem(6px);

    &.available {
      cursor: pointer;
      background: $gray-200;

      &:hover {
        background: darken($gray-200, 5%);
      }
    }

    &.not-available, &.booked {
      background: $red !important;

      &:hover {
        background: $red !important;
      }
    }

    &.selected {
      background: $gray-400;

      &:hover {
        background: $gray-400;
      }

      &:before {
        content: "";
        position: absolute;
        top: rem(-6px);
        left: rem(-1px);
        right: 0;
        bottom: rem(-6px);
        border-top: 2px solid $green;
        border-bottom: 2px solid $green;
        z-index: 10;
      }

      &--from {
        &:before {
          left: rem(-6px);
          border-left: 2px solid $green;
          border-top-left-radius: rem(4px);
          border-bottom-left-radius: rem(4px);
        }
      }

      &--to {
        &:before {
          right: rem(-6px);
          border-right: 2px solid $green;
          border-top-right-radius: rem(4px);
          border-bottom-right-radius: rem(4px);
        }
      }
    }
  }
}