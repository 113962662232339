﻿@import "../utils/mixins";
@import "../variables";

/*
-----------------------------------------
  1.00 Floorplans
-----------------------------------------
*/

.floorplans {

  canvas {
    cursor: grab;
    opacity: 0.6;
    filter: grayscale(100%);
    transition: opacity 0.15s ease-in-out;

    &:hover, &:active, &:focus {
      outline: 0 !important;
    }
  }

  .dropdown-menu {
    z-index: $zindex-popover + 1;
  }

  .popup-open canvas {
    opacity: 0.25;
    transition: opacity 0.15s ease-in-out;
  }

  &__filters {
    position: relative;
    z-index: 1;
  }

  &__map {
    position: relative;
    height: 550px;
    background: $white;
    border-radius: $border-radius;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      height: 50vh;
    }

    .openseadragon-canvas {
      &:hover, &:active, &:focus {
        outline: 0 !important;
      }
    }

    .js-floorplans-alert {
      position: relative;
      top: 30px;
      left: 50%;
      z-index: 1;
      max-width: 400px;
      margin: 0 30px 30px;
      transform: translateX(calc(-50% - 30px));
    }

    .js--tooltip {
      position: absolute;
      left: -8px;

      .js--tooltip-inner {
        text-align: left;
        max-width: initial;
        
        div {
          white-space: pre;
        }
      }

      .arrow {
        left: 10px;
      }
    }
  }

  &__controls {
    position: absolute;
    right: 20px;
    bottom: 20px;
    overflow: hidden;
    width: 26px;
    height: 55px;
    text-align: center;
    background: $white;
    border: 1px solid $gray-300;
    border-radius: 13px;
    box-shadow: 0 2px 6px 0 rgba($gray-600, 0.25);

    path {
      fill: $text-color;
    }

    span {
      display: block;
      height: 27px;
      line-height: 33px;
      cursor: pointer;

      + span {
        border-top: 1px solid $gray-300;
        line-height: 29px;
      }

      &:active {
        background-color: $gray-300;
      }
    }
  }

  &__filters-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1061;
    overflow-y: auto;
    width: 340px;
    max-height: calc(100% - 60px);
    margin: 30px 0;
    transform: translateX(-50%);
    background-color: $white;
    border-radius: $border-radius;

    &__inner {
      height: calc(100% - 100px);
      padding: 20px 20px 0;
      overflow-y: auto;
      border-bottom: 1px solid $gray-300;
      @include scrollbar();
    }

    .floorplans__filters-modal__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-size: rem(12px);
    }

    &__title {
      margin: 0 0 10px;
    }

    &__list {
      margin-bottom: 20px;
      padding-left: 0;
      list-style-type: none;

      .checkbox {
        margin: 5px 0;
      }
    }

    &__actions {
      padding: 20px;
    }
  }

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($gray-900, 0.75);
  }

  &__key {
    margin: 20px 0;
    font-size: rem(14px);
    font-weight: 500;
    text-align: center;
    color: $gray-900;

    .list-inline {
      margin-left: -15px;

      > li {
        margin-bottom: 10px;
        padding-left: 15px;
      }
    }

    svg {
      transform: translateY(1px);
    }

    path,
    ellipse {
      fill: $gray-900;
    }
  }

  &__legend {
    opacity: 0.33;
    @include transition(0.5s);

    &:hover {
      opacity: 1;
    }
  }

  .loader {
    height: 500px;
  }

  .icon {
    width: 16px;
    height: 16px;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;

    &.available {
      background: $green;
      border: 3px solid $green;
    }

    &.booked {
      background: $red;
      border: 3px solid $red;
    }

    &.disabled {
      &.available {
        background: fade($green, 30%);
        border-color: fade($green, 30%);
      }

      &.booked {
        background: fade($red, 30%);
        border-color: fade($red, 30%);
      }
    }

    &.inBasket {
      background: $orange !important;
      border: 2px solid $orange !important;
    }
  }

  .svg {
    width: 16px;
    height: 16px;
    background: $gray-100;

    &::after {
      content: ' ';
      animation-duration: 2s;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      position: absolute;
      transform: translate(-50%, -50%);
    }


    &.office {
      background: $gray-300;
    }

    &.dedicated {
      border-radius: 50%;
      background: $gray-300;
    }

    &.hot {
      // triangle
      clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);

      &::after {
        width: 6px;
        height: 6px;
        clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
        top: 10px;
        left: 8px;
      }
    }

    &.other {
      // polygon
      clip-path: polygon(78% 100%, 21% 100%, 0% 43%, 50% 6%, 100% 43%);

      &::after {
        width: 6px;
        height: 6px;
        clip-path: polygon(78% 100%, 21% 100%, 0% 43%, 50% 6%, 100% 43%);
        top: 9px;
        left: 8px;
      }
    }


    &.available {
      background: $green;

      &::after {
        background: $green;
      }

      &.searched {
        &::after {
          animation-name: flash-green;
        }
      }
    }

    &.booked {
      background: $red;

      &::after {
        background: $red;
      }

      &.searched {
        &::after {
          animation-name: flash-red;
        }
      }
    }

    &.inBasket {
      background: $orange !important;

      &::after {
        background: $orange !important;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  @keyframes flash-green {
    from, 50%, to {
      background: $green;
    }

    25%, 75% {
      background: #fff;
    }
  }

  @keyframes flash-red {
    from, 50%, to {
      background: $red;
    }

    25%, 75% {
      background: #fff;
    }
  }
}

.popover-floorplan {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  opacity: 1 !important;

  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    z-index: $zindex-modal-backdrop+1;
  }

  &__close-icon {
    position: absolute;
    top: rem(12px);
    right: rem(492px);
    font-size: rem(40px);
    line-height: 1;
    color: $white;
    cursor: pointer;
    z-index: $zindex-modal-backdrop+2;

    @include media-breakpoint-down(sm) {
      right: calc(100% - #{rem(46px)});
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: $white;
    padding: rem(20px);
    padding-bottom: rem(24px);
    width: rem(480px);
    z-index: $zindex-modal;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbar();

    @include media-breakpoint-down(sm) {
      padding: rem(16px);
      width: calc(100% - #{rem(52px)});
    }

    .booking-resource-selector {
    }

    .booking-resource-info {
      &--title {
        font-size: rem(24px);
      }

      &--description {
        img {
          max-width: 100%;
        }
      }
    }

    .booking-services-form {
      .divider {
        border-top: 1px solid $gray-300;
      }
    }

    .date-picker-container  {
      flex: 0 1 49% !important;
      max-width: 49%;
    }

    .teams-accordion, .prev-guests-accordion {
      .icon-attendee-add {
        color: $gray-600;
  
        &:hover {
          color: $gray-900;
        }
      }
      
      .add-team {
        color: $gray-600;
  
        &:hover {
          background-color: $gray-200;
          border-radius: 8px;
        }
      }
  
      .attendee-added {
        background-color: $green-200;
        color: $green;
        border-radius: 8px;
        @extend .icon-check;
  
        &:hover {
          background-color: $red-200;
          color: $red;
          @extend .icon-close;
        }
      }
    }

    .new-attendee-accordion {
      .btn.disabled {
        cursor: initial;
      }
    }

    .input-radio-box {
      margin: 0 !important;
      margin-bottom: 10px !important;
      height: rem(32px);
    }
  }

  &__actions {
  }
}

body.seadragon-popup-open {
  
}