.rwe-cta-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    min-height: 200px;
    width: 500px;

    @include rwe-breakpoint-only(tablet) {
        width: 408px;
    }

    @include rwe-breakpoint-down(mobile) {
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        flex: 1;
        padding: 24px;
    }
}